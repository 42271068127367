import React,{memo, useContext} from 'react'
import { OrderDetailContext } from '../useOrderDetail'
import { Card, Descriptions, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { formatAmount } from '@/utils/utils'
import numeral from 'numeral'
import { EntityTypeEnum } from '@src/constant/entity'
import { Link } from 'react-router-dom'

export const OrderBasicInfo: React.FC<{}> = memo(()=>{
  const context = useContext(OrderDetailContext)
  const columns:ColumnsType<API.Order.merchandise> = [
    {dataIndex: 'name',title:'货品名称'},
    {dataIndex: 'quantity',title:<>重量({context?.data?.unitOfMeasureShow})</>,render:(value)=>numeral(value).format('0,0.[00000000]')},
    {dataIndex: 'unitPrice',title:'不含税单价(元)',render:formatAmount},
    {dataIndex: 'totalPrice',title:'不含税金额(元)',render:formatAmount},
    {dataIndex: 'vatAmount',title:'增值税(元)',render:formatAmount},
    {dataIndex: 'totalAmount',title:'订单金额(元)',render:formatAmount},
  ]
    return <Card title='订单信息'>
    <Descriptions column={3}>
      <Descriptions.Item label="采购方">
        <Link to={`/entity/transaction/enterprise/${context?.data?.buyer?.id}`}>{context?.data?.buyer?.name}</Link>
      </Descriptions.Item>
      <Descriptions.Item label="类型">
        {EntityTypeEnum[context?.data?.buyer?.type!]||context?.data?.buyer?.type}
      </Descriptions.Item>
      <Descriptions.Item label="统一社会信用代码">
      {context?.data?.buyer?.taxNo}
      </Descriptions.Item>
      <Descriptions.Item label="销售方">
        <Link to={`/entity/transaction/individuality/${context?.data?.supplier?.id}`}>{context?.data?.supplier?.name}</Link>
      </Descriptions.Item>
      <Descriptions.Item label="类型">
        {EntityTypeEnum[context?.data?.supplier?.type!]||context?.data?.supplier?.type}
      </Descriptions.Item>
      {
        {
          'INDIVIDUAL_BUSINESS':<Descriptions.Item label="统一社会信用代码">
          {context?.data?.supplier?.taxNo}
          </Descriptions.Item>,
          'INDIVIDUAL':<Descriptions.Item label="身份证号">
          {context?.data?.supplier?.idNumber}
          </Descriptions.Item>,
          'ENTERPRISE':<Descriptions.Item label="统一社会信用代码">
          {context?.data?.supplier?.taxNo}
          </Descriptions.Item>
        }[context?.data?.supplier?.type||'INDIVIDUAL_BUSINESS']
      }
      <Descriptions.Item label="补差金额">
      {context?.data?.differentialAmount?formatAmount(context?.data?.differentialAmount):'无'}
      </Descriptions.Item>
    </Descriptions>
    <Table columns={columns} dataSource={context?.data?.merchandises} pagination={false}></Table>
  </Card>
})