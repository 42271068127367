// 交易级别Select
export const levelOptions = [
  {
    label: "全部",
    value: null,
  },
  {
    label: "零散交易者",
    value: "SPORADIC_TRADER",
  },
  {
    label: "小型交易者",
    value: "SMALL_TRADER",
  },
  {
    label: "中型交易者",
    value: "MIDSIZE_TRADER",
  },
  {
    label: "大型交易者",
    value: "LARGE_TRADER",
  },
];

// 来源
export const sourceOptions = [
  {
    label: "全部",
    value: null,
  },
  {
    label: "CRM",
    value: "CRM",
  },
  {
    label: "EP",
    value: "EP",
  },
  {
    label: "货主端",
    value: "CONSIGNORS",
  },
];

// 实体状态
export const statusOptions = [
  {
    label: "全部",
    value: null,
  },
  {
    label: "正常",
    value: "1",
  },
  {
    label: "禁用",
    value: "0",
  },
];
export const customerStatusList = [
  {
    label: "正常",
    value: "正常",
    color: "rgba(0, 209, 139, 1)",
  },
  {
    label: "禁用",
    value: "禁用",
    color: "rgba(245, 63, 63, 1)",
  },
];

// 实体状态
export const indivdualityStatusOptions = [
  {
    label: "全部",
    value: null,
  },
  {
    label: "正常",
    value: 1,
  },
  {
    label: "禁用",
    value: 0,
  },
  {
    label: "禁用-申请注销",
    value: -1,
  },
  {
    label: "禁用-已注销",
    value: -2,
  },
];

// 实体类型
export const EntityTypes = [
  {
    label: "企业",
    value: "ENTERPRISE",
  },
  {
    label: "个体户",
    value: "INDIVIDUAL_BUSINESS",
  },
  // {
  //     label: '自然人',
  //     value: 'INDIVIDUAL'
  // },
];
