import { transMapToOptions } from "@src/utils/utils"

export const PushChannelMap = {
  // "MOBILE": "手机号",
  "MP_WX": "公众号",
  "CP_WX": "企业微信"
}

export const PushChannelOptions = transMapToOptions(PushChannelMap)

