import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row, Input, Select, message } from "antd";
import {
  getRoleLists,
  getJobOrDepLists,
  addAccount,
} from "../../../../../../services/api/system/page/addUser";
import "./index.scss";
import { RexIdNo } from "@/utils/RegExp";
type type_modal_operator = {
  (a: boolean): void;
};

type type_user_modal = {
  isAdd: boolean;
  addModal: type_modal_operator;
  cb: () => void;
};
const UserModal: React.FC<type_user_modal> = ({ isAdd, addModal, cb }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [value, setValue] = useState<any>({
    name: "",
    job: "",
    jobs: [],
    department: "",
    departments: [],
    email: "",
    account: "",
    role: [],
    roles: [
      {
        name: "",
        id: "",
      },
    ],
    wx: "",
  });

  const handleCancel = () => {
    form.resetFields();
    addModal(false);
  };

  const onFinish = (values: any) => {
    const addData = {
      account: values.account,
      roleIds: values.role,
      department: values.department,
      email: values.email,
      name: values.name,
      position: values.job,
      wxAccount: values.wx,
      idNo: values.idNo,
    };
    addAccount(addData).then((data) => {
      if (data.successful) {
        messageApi.open({
          type: "success",
          content: "新增成功",
          onClose: () => {
            handleCancel();
            cb();
          },
        });
      } else {
        messageApi.open({
          type: "error",
          content: data.message || "添加失败",
        });
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  });

  useEffect(() => {
    getRoleLists().then((data) => {
      if (data.code === 200) {
        const role = data.data.records.map((item: any) => ({
          name: item.name,
          id: item.id,
        }));
        setValue((value: any) => ({
          ...value,
          roles: role,
        }));
      } else {
        messageApi.open({
          type: "error",
          content: data.message || "添加失败",
        });
      }
    });
    getJobOrDepLists("DEPARTMENT").then((data) => {
      setValue((value: any) => ({
        ...value,
        departments: data.data,
      }));
    });
    getJobOrDepLists("POSITION").then((data) => {
      setValue((value: any) => ({
        ...value,
        jobs: data.data,
      }));
    });
  }, []);

  return (
    <>
      <Modal
        open={isAdd}
        title="用户新增"
        onCancel={handleCancel}
        centered
        width={600}
        footer={null}
      >
        {contextHolder}
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={value}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: "请输入！" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="职位"
                name="job"
                rules={[{ message: "最多50个字", max: 50 }]}
              >
                {/* <Select>
                  {value!.jobs!.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select> */}
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="部门"
                name="department"
                rules={[{ message: "最多50个字", max: 50 }]}
              >
                <Input />
                {/* <Select>
                  {value!.departments!.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="邮箱"
                name="email"
                rules={[
                  { required: false, message: "请输入！" },
                  {
                    pattern:
                      /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,

                    message: "邮箱格式不正确",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="账号"
                name="account"
                rules={[
                  { required: true, message: "请输入！" },
                  { pattern: /^1[0-9]{10}/, message: "请输入手机号格式的账号" },
                ]}
              >
                <Input maxLength={11} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="角色"
                name="role"
                rules={[{ required: true, message: "请输入！" }]}
              >
                <Select mode="multiple">
                  {value!.roles!.map((item: any) => (
                    <Select.Option key={item.name} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="企业微信账号"
                name="wx"
                rules={[{ message: "请输入！", pattern: /[a-zA-Z]*/ }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="身份证"
                name="idNo"
                rules={[{ pattern: RexIdNo, message: "请输入正确身份证格式" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col offset={8} span={4}>
              <Form.Item wrapperCol={{ span: 16 }}>
                <Button
                  onClick={(e) => {
                    handleCancel();
                  }}
                >
                  取消
                </Button>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item wrapperCol={{ span: 16 }}>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default UserModal;
