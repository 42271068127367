/**
 * @author 阮东辉
 * @time 2023/3/15
 * @type 图片/视频/音频
 * */ 


import React, {useState, useEffect, forwardRef, useImperativeHandle, useRef} from "react";
import { Form, Input,Radio, InputNumber} from "antd";
import UploadCom from "../../../UploadCom";
import './index.scss'
import { useDispatch} from 'react-redux'
import {UPDATE_BASIC, UPDATE_RULE} from '../../../../store/constants'
const FormItem = Form.Item
const { TextArea } = Input;
/**
 * @param{type} string 区别是限制条件或者基本类型数据 
 * */ 

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 24,
  },
};
const radioList = [
  {
    label:'读写',
    value:'WRITE'
  },
  {
    label:'只读',
    value:'READ'
  },
  {
    label:'隐藏',
    value:'HIDE'
  }
]

const uploadParams ={
  'photo':{
    accept:'image/jpeg,image/png,image/gif',
    name:'图片'
  },
  'video':{
    name:'视频',
    accept:'.avi,.wmv,.mpeg,.mp4,.m4v,.mov,.asf,.flv,.f4v,.rmvb,.rm,.3gp,.vob,image/jpeg,image/png,image/gif'
  },
  'audio':{
    name:'音频',
    accept:'.mp3,.wma,.wav,.ape,.flec,.ogg,.aac,image/jpeg,image/png,image/gif'
  }
}

const ImageTemplate =(props:any, ref:any) => {
  const {infoType = 'basicInfo', type, infoData, isInfo} = props
  const dispatch = useDispatch<any>()
  const [formBacis] = Form.useForm();
  const [formCondition] = Form.useForm()
    // 本地缓存数据
    const [local, setLocal] = useState<any>({})
  // const basicForm = useRef<any>({})
  const [basic, setBasic] = useState<any>({})

  // rules
  const [rules, setRules] = useState<any>({})
  const numberRef = useRef({
    minValue:0,
    maxValue:0
  })
  // 本地存贮 最大最小
  const [curData, setCurData] = useState<any>({
    minValue:0,
    maxValue:0
  })
  // 基本信息
  const onFinish = (values:any) => {
    // return 
    return values
  } 
  // 限制条件
  const onFinishCon = (values:any) => {
    console.log(values, 'cccccc')
    // return values
  }
  const reset= () => {
    formBacis.resetFields()
    formCondition.resetFields()
  }
  const updateState = ( type:string, value:any) => {
    if(infoType === 'basicInfo'){
      setBasic({
        ...basic,
        [type]:value
      })
      dispatch({
        type:UPDATE_BASIC, payload:{
          [type]:value
        }
      })
    }
    if(infoType === 'condition'){
      setRules({
        ...rules,
        [type]:value
      })
      dispatch({
       type:UPDATE_RULE, payload:{
          [type]:value
        }
      })
    }
  }  
  const formValidate = () => {
    formBacis.validateFields()
    formCondition.validateFields()
  }
  useEffect(() => {
    if(infoData?.name && infoData?.name !==''){
  // eslint-disable-next-line react-hooks/exhaustive-deps
      formBacis.setFieldValue('name', infoData.name)
    }
    if(infoData.id && infoData.id !== undefined){
      if(!local?.id || local?.id !== infoData?.id){
          setLocal(infoData)
    
        numberRef.current.minValue = infoData?.rules?.limitUploadQuantity?.minValue || '';
        numberRef.current.maxValue = infoData?.rules?.limitUploadQuantity?.maxValue || ''
      }
    }else{
      // console.log('新增')
    }
    
  }, [infoData]) // eslint-disable-line
  useEffect(() => {
    reset()
  }, [type]) // eslint-disable-line
  useImperativeHandle(ref, () =>({
    onFinish,
    onFinishCon,
    reset,
    formValidate
  }))
  useEffect(() => {
    setCurData({
      minValue:infoData?.rules?.limitUploadQuantity?.minValue || 0,
      maxValue:infoData?.rules?.limitUploadQuantity?.maxValue || 0
    })

    },[infoData])
  const renderBasicInfo = () => {
    return <div>
      <Form {...layout} form={formBacis} onFinish={onFinish} name='photo_basic'>
        <FormItem name='name' label='字段名称' rules={[
          {
            required: true, message:'请输入字段名称'
          }
        ]}>
          <Input disabled={isInfo} className="ipt" maxLength={10} defaultValue={infoData?.name} onChange={(e) => {
            updateState( 'name', e.target.value)
            formBacis.setFieldValue('name', e.target.value)
          }}></Input>
          <span>不超过10个字，支持中文，英文，数字，字母组合</span>
        </FormItem>
        <FormItem label='默认值' >
          <span className={`${isInfo && "not_allowed"}`}>
            <UploadCom 
            disabled={isInfo}
            // @ts-ignore
            {...uploadParams[type]}
            value={infoData?.defaultValue?.fileInfos}
            multiple={true}
            cb={(value:any, file:any) => {
              // const fileType = file.name.lastIndexOf('.')
              // let str = file.name.substring(fileType)
              updateState('fileInfos', value)
            }}></UploadCom>
          </span>
        </FormItem>
        <FormItem label='用户权限'name='accessType'>
          <Radio.Group disabled={isInfo} onChange={(e) => {
            updateState('accessType', e.target.value)
          }} defaultValue={infoData?.accessType}>
            {radioList.map((item:any) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)}
          </Radio.Group>
          <span>读写:允许用户查看和编辑该字段;只读:用户只能查看该字段;隐藏:用户无法查看该字段</span>
        </FormItem>

        <FormItem label='字段说明' name='comment'>
          <Input disabled={isInfo} className="ipt" defaultValue={infoData?.comment} onChange={e => {
            updateState('comment', e.target.value)
          }}></Input>
          <span>字段说明:显示在字段输入框之后(一段在输入框下方),用于提示用户输入内容及规则</span>
        </FormItem>
        <FormItem label='详细说明' name='haveRequire'>
          <Radio.Group disabled={isInfo} defaultValue={infoData?.rules?.haveRequire} onChange={e => {
            updateState('haveRequire', e.target.value)
          }}>
            {
              [{label:'需要', value:1},{label:'不需要',value:0}].map((item:any) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)
            }
          </Radio.Group>
        </FormItem>
        <FormItem name='requireTitle' label=''>
          <Input disabled={isInfo} defaultValue={infoData?.rules?.requireTitle} placeholder="详细说明标题" className="ipt" style={{marginLeft:'20%'}} onChange={e => updateState('requireTitle', e.target.value)}></Input>
        </FormItem>
        <FormItem label='' name='requireContent'>
          <TextArea disabled={isInfo} defaultValue={infoData?.rules?.requireContent} rows={4} placeholder="详细说明"  style={{width:'200px', marginTop: '15px', marginLeft:'20%'}} onChange={e => updateState( 'requireContent', e.target.value)}/>
        </FormItem>
      </Form>
    </div>
  }
  const renderCondition = () => {
    return <div>
      <Form {...layout} form={formCondition} onFinish={onFinishCon}>
        <FormItem label ='限制上传数量'>
          <div  style={{display:'flex', alignItems:'center', marginTop:'5px'}}>
            <span>
              最少: <InputNumber disabled={isInfo} defaultValue={infoData?.rules?.limitUploadQuantity?.minValue} max={numberRef.current.maxValue} onChange={(e) => {
                setCurData({
                  ...curData,
                  minValue: e
                })  
                numberRef.current.minValue= e
                updateState( 'limitUploadQuantity', {
                  ...curData,
                  ...numberRef.current,
                  minValue: e
                })
              }}></InputNumber> 
            </span>
            <span style={{margin:'0 20px'}}>  最多: <InputNumber disabled={isInfo} defaultValue={infoData?.rules?.limitUploadQuantity?.maxValue}  min={numberRef.current.minValue} onChange={(e) => {
              numberRef.current.maxValue=e
              setCurData({
                ...curData,
                
                maxValue: e
              })
              updateState('limitUploadQuantity', {
                ...rules.limitUploadQuantity,
                ...numberRef.current,
                maxValue: e
              })
              }}></InputNumber> </span> 
            <span>支持纯数字, 无小数点。若需要配置相等关系，最大最少配置的数量相等即可</span>
          </div>
        </FormItem>
        <FormItem label ='限制上传体积'>
          <div style={{marginLeft: '40px', marginTop: '10px', marginBottom: '10px'}}>
            最大：<InputNumber disabled={isInfo} defaultValue={infoData?.rules?.limitUploadVolume?.maxValue} className="ipt" onChange={(e) => {
               
              updateState('limitUploadVolume', {
                // ...curData,
                maxValue: e
              })
              }}></InputNumber>mb
          </div>
        </FormItem>
        {/* <FormItem label='配置警示文案' name=''>
          <Input placeholder="请输入警示文案内容，不超过20个汉字" maxLength={20} className="ipt"></Input>
          <span>警示文案可不填，按照限制条件的默认规则进行警示</span>
        </FormItem> */}
      </Form>
    </div>
  }

  return <div>
    {
      infoType === 'basicInfo'? renderBasicInfo() : renderCondition()
    }
    {/* {
      infoType === 'condition'?<div style={{width: '100%', display:'flex',justifyContent:'flex-end'}}>
      <Button type="primary" onClick={handleSumit}>保存</Button>
    </div>: null
    } */}
  </div>
  
}

export default forwardRef(ImageTemplate)  

