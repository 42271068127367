import BreadCrumbBar from '@src/components/BreadCrumbBar';
import { Button, Card, Col, Form, Input, InputNumber, message, Radio, Row, Select, Space } from 'antd'
import classNames from "classnames";
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { addProductInfo, editProductInfo, getCompanyList, getProductInfo } from '../../promise';
const defaultProps = {}
type props = {}
export type FinancingProductDetailProps = Required<typeof defaultProps> & props
export const FinancingProductDetail: React.FC<FinancingProductDetailProps> = memo((props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm()
  const location = useLocation();
  const [coreEnterpriseOptions, setCoreEnterpriseOptions] = useState([])
  const breads = [
    {
      label: "融资产品",
      path: "/customer/financing/product",
    },
    {
      label: location.state?.productId ? '编辑融资产品' : "添加融资产品",
      path: "",
    },
  ];
  const isType = useMemo(() => location.state?.productId ? 'edit' : 'add', [location.state])
  const getProductInfoService = async () => {
    const res = await getProductInfo({ id: location.state?.productId })
    const handleCoreEnterpriseList = res.data.coreEnterpriseList?.map((item: { id: any; }) => item.id)
    form.setFieldsValue({ riskFactor: '0.8', fixedCreditCycle: '30', maxLoanAmount: '1000000', ...res.data, coreEnterpriseList: handleCoreEnterpriseList });
  }
  // 获取核心企业列表
  const getCompanyListService = async () => {
    const res = await getCompanyList();
    if (res?.code === 200) {
      setCoreEnterpriseOptions(res?.data?.records);
    }
  }
  useEffect(() => {
    if (location.state?.productId) {
      getProductInfoService()
    } else {
      form.setFieldsValue({ riskFactor: '0.8', fixedCreditCycle: '30', maxLoanAmount: '1000000', });
    }
    getCompanyListService()
  }, [location.state])
  const onSubmit = async (values: API.FinancingProduct.Item) => {
    const handleCoreEnterpriseList = coreEnterpriseOptions?.filter((item: { id: any; }) => values.coreEnterpriseList.some((id: any) => {
      return item.id == id
    }))

    if (isType === 'add') {
      const res: any = await addProductInfo({ ...values, coreEnterpriseList: handleCoreEnterpriseList })
      if (res.code === 200) {
        message.success('添加成功')
        navigate(-1)
      } else {
        message.error(res.message)
      }
    } else if (isType === 'edit' && location.state?.productId) {
      const res: any = await editProductInfo({ ...values, coreEnterpriseList: handleCoreEnterpriseList, id: location.state?.productId })
      if (res.code === 200) {
        message.success('编辑成功')
        navigate(-1)
      } else {
        message.error(res.message)
      }
    }
  }
  return <div className={classNames("public_detail_page")}>
    <BreadCrumbBar breads={breads}></BreadCrumbBar>
    <Card>
      <Form initialValues={{ loanCycleType: 'SETTLEMENT_TIME', repaymentMode: 'DISPOSABLE', earlyRepaymentSupport: false, status: 1 }} name='FinancingProductDetail' form={form} labelCol={{ span: 8 }} onFinish={(values) => onSubmit(values)} >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item label="产品名称" name="name" rules={[{ required: true }]}>
              <Input maxLength={20} />
            </Form.Item></Col>
          <Col span={8}>
            <Form.Item label="融资公司" name="financeCompany" rules={[{ required: true }]}>
              <Input maxLength={20} />
            </Form.Item></Col>
          <Col span={8}>
            <Form.Item label="借款周期" name="loanCycleType" rules={[{ required: true }]}>
              <Radio.Group name='cycle' defaultValue='SETTLEMENT_TIME'>
                <Radio defaultChecked={true} value={'SETTLEMENT_TIME'}>按订单结算日期</Radio>
              </Radio.Group>
            </Form.Item></Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item label="风险系数" name="riskFactor" rules={[{ required: true }]}
              getValueFromEvent={(ev) => {
                return ev.target.value?.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
              }}
            >
              <Input maxLength={20} />
            </Form.Item></Col>
          <Col span={8}>
            <Form.Item label="固定授信周期" name="fixedCreditCycle" rules={[{ required: true }]}>
              <Input maxLength={20} />
            </Form.Item></Col>
          <Col span={8}>
            <Form.Item label="最大借款金额" name="maxLoanAmount" rules={[{ required: true }]}>
              <Input maxLength={20} />
            </Form.Item></Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item label="年利率" name="annualInterestRate" rules={[{ required: true }]}>
              <InputNumber formatter={(value) => `${value}%`}
                // parser={(value) => value!.replace('%', '')} 
                min={0} />
            </Form.Item></Col>
          <Col span={8}>
            <Form.Item label="核心企业" name="coreEnterpriseList" rules={[{ required: true }]}>
              <Select mode='multiple' allowClear fieldNames={{ label: 'name', value: 'id' }}
                options={coreEnterpriseOptions}></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item label="还款模式" name="repaymentMode" rules={[{ required: true }]}>
              <text>到期一次还本付息</text>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item label="是否支持提前还款" name="earlyRepaymentSupport" rules={[{ required: true }]}>
              <text>否</text>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8} offset={1}>
            <Form.Item label="" name="status" rules={[{ required: true }]}>
              <Radio.Group name='isDisable'>
                <Radio value={1}>启用</Radio>
                <Radio value={0}>禁用</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item wrapperCol={{ span: 24, offset: 10 }}>
          <Space size={'large'}>
            <Button htmlType="reset" onClick={() => navigate(-1)}>取消</Button>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  </div>
})
FinancingProductDetail.defaultProps = defaultProps