import axios from 'axios';
import qs from 'qs';
import { message } from 'antd';
import { invoiceURL } from '@src/utils/invoiceURL';

let current_env = process.env.REACT_APP_ENV || 'production'
console.log(process.env, current_env, 'process')

const cross_env = {
    // 'development': 'https://test.api.saikul.com/admin',
    'development': 'https://dev.api.saikul.com/admin',
    'test': 'https://test.api.saikul.com/admin',
    'production': 'https://api.saikul.com/admin',
}[current_env]
// axios.defaults.baseURL = cross_env || 'https://api.saikul.com/admin'
axios.interceptors.request.use(
    (config: any) => {
        if (config.method === 'get' || config.method === 'delete') {
            config.paramsSerializer = function (params: any) {
                return qs.stringify(params, { arrayFormat: 'repeat' })
            }
        }
        if (!config.baseURL) {
            if (!/^(http)/.test(config.url)) {
                if (config.headers?.baseUrl == 'SP') {
                    config.url = `${invoiceURL()}${config.url}`
                } else {
                    config.url = `${cross_env || 'https://api.saikul.com/admin'}${config.url}`
                }
            }
        }

        let field = JSON.parse(window.localStorage?.getItem('operation_authorization') as string);
        if (field) {
            config.headers['Authorization'] = `Bearer ${field?.['access_token']}`
        }
        return config;
    },
    error => {
        return Promise.reject(error.response);
    }
);

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // 此处做错误处理的一些逻辑，比如请求超时返回登录页面
        if (error.response.status == 401) {
            if (window.location.href.indexOf('login') === -1) {
                message.error('登录信息过期')
                localStorage.removeItem('operation_authorization');
                location.href = `/login#redirect=${location.href}`
            } else {
                if (error.response.config.url.indexOf('/sinzetech-auth/oauth/token') != -1) {
                    message.error(error.response.data.error_description)
                }
            }
        } else if (error.response.status == 400) {
            if (error.response.config.url.indexOf('/sinzetech-auth/oauth/token') != -1) {
                message.error(error.response.data.error_description)
            } else {
                message.error(error.response.data.msg)
            }
        } else {
            if (error.response.config.url.indexOf('/sinzetech-auth/oauth/token') != -1) {
                message.error(error.response.data.error_description)
            } else {
                // 下载文件失败转文字
                if (error.response.config.responseType == 'blob') {
                    let blob = new Blob([error.response.data])
                    let reader = new FileReader()
                    reader.readAsText(blob, 'utf-8')
                    reader.onload = function () {
                        try {
                            let data = JSON.parse((reader as any).result)
                            message.error(data.msg)
                        } catch (e) {
                            message.error('系统错误')
                        }
                    }
                } else if (error.response.data instanceof ArrayBuffer) {
                    const td = new TextDecoder('utf-8')
                    const dataStr = td.decode(error.response.data)
                    const okData = JSON.parse(dataStr ?? "{}");
                    message.error(okData.msg)
                } else {
                    message.error(error.response.data.msg)
                }
            }
        }
        return Promise.reject(error.response)
    }
);
