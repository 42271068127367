/**
 * @author 阮东辉
 * @time 2023/3/14
 * 页面路由数据
 */
import React, { useEffect, lazy } from "react";
import { Navigate, useRoutes, useNavigate } from "react-router-dom";
import {
  System,
  Login,
  User,
  Role,
  Authority,
  Error,
  DataBase,
  Template,
  Fields,
  Management,
  CreateManagement,
  FieldList,
  ManagementInfo,
} from "../pages";
import AddFeild from "../pages/Evidence/pages/Fields/pages/Add";
import AddEvi from "../pages/Evidence/pages/Management/pages/AddEvidence";
import Evidence from "../pages/Evidence";
import AddTemplate from "../pages/Evidence/pages/Template/pages/AddTemplate";
import TaxAdministration from "../pages/BusinessAgent/pages/TaxAdministration";
import Ticket from "../pages/Ticket";
import Agency from "../pages/BusinessAgent/pages/Agency";
import TaxManagement from "../pages/System/pages/TaxManagement";
import AddTax from "../pages/System/pages/TaxManagement/pages/Add";
import InvoicingTask from "../pages/BusinessAgent/pages/InvoicingTask";
import InvoicingTaskInfo from "../pages/BusinessAgent/pages/InvoicingTask/InvoceInfo";
import InvoicingParty from "@src/pages/BusinessAgent/pages/Ticket/InvoicingParty";
// 代办业务
import Business from "../pages/BusinessAgent";
import AddAdministration from "../pages/BusinessAgent/pages/TaxAdministration/pages/add";
import NewAddAdministration from "../pages/BusinessAgent/pages/TaxAdministration/pages/newAdd";
import InfoAdministration from "../pages/BusinessAgent/pages/TaxAdministration/pages/Info";
import LogoutApplyAdd from "../pages/BusinessAgent/pages/logoutApply/add/add";
import LogoutApply from "../pages/BusinessAgent/pages/logoutApply/logoutApply";
import AgencyCancellation from "../pages/BusinessAgent/pages/AgencyInfo";
import OpenTask from "@src/pages/BusinessAgent/pages/openTask/openTask";

// 客户管理
import Customer from "../pages/Customer";
import CustomerManagement from "../pages/Customer/Management";
import CustomerManagementAdd from "../pages/Customer/Management/pages/add";

import UserGroup from "../pages/System/pages/UserGroup";
import UserGroupAdd from "../pages/System/pages/UserGroup/pages/add";
import CustomerInfo from "../pages/Customer/Management/pages/info";
import RelatedEntityDetail from "../pages/Customer/Management/pages/info/pages/RelatedEntity/Detail";

import { AddEntity } from "../pages/Entity/Add";
import { EditEntity } from "@src/pages/Entity/Eidt";
import { InvoiceSetting } from "@src/pages/System/pages/InvoiceSetting";
import Consignor from "@src/pages/Consignor";
import { RecoverManagement } from "@src/pages/Consignor/RecoverManagement";
import Ledger from "@src/pages/BusinessAgent/pages/ledger/ledger";
import { RecoverList } from "@src/pages/Consignor/RecoverList";

// 供应链金融
import Supply from "@src/pages/Supply";
import FinancingOrders from "@src/pages/Supply/pages/FinancingOrders";
import FinancingOrdersInfo from "@src/pages/Supply/pages/FinancingOrders/Details";
import { FinancingProduct } from "@src/pages/Supply/pages/FinancingProduct/list";
import { FinancingProductDetail } from "@src/pages/Supply/pages/FinancingProduct/detail";
import SupplyAccess from "@src/pages/Supply/pages/SupplierAccess";
import SupplyAccessDetail from "@src/pages/Supply/pages/SupplierAccess/Details"; // 供应商准入详情
import SupplyAccessUpdate from "@src/pages/Supply/pages/SupplierAccess/Update";
import EnterpriseAccess from "@src/pages/Supply/pages/EnterpriseAccess"; // 核心企业准入

// 实体交易
import Entity from "@src/pages/Entity";
import Enterprise from "@src/pages/Entity/Enterprise";
import Individuality from "@src/pages/Entity/Individuality";
import Natural from "@src/pages/Entity/Natural";
import { EnterpriseDetail } from "@src/pages/Entity/Enterprise/Detail";
import { IndividualityDetail } from "@src/pages/Entity/Individuality/Detail";
import { NaturalDetail } from "@src/pages/Entity/Natural/Detail";
import CreateEntry from "@src/pages/Entity/CreateEntry";
// 订单
import ProcureOrder from '@/pages/Entity/orderManagement/ProcureOrder'
import Classification from '@/pages/Entity/platformClassification'
import NewPurchaseOrder from "@src/pages/Entity/orderManagement/ProcureOrder/detail";
import EnterpriseAccessDetail from "@src/pages/Supply/pages/EnterpriseAccess/Detail";
export const routeData: Array<any> = [
  {
    path: "/",
    element: <Login />,
    meta: {
      key: "login",
    },
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      key: "login",
    },
  },
  {
    path: "*",
    element: <Error />,
    meta: {
      key: "error",
    },
  },
  {
    path: "/",
    // redirect:'/system'
    element: <Navigate to="/system"></Navigate>,
    meta: {
      key: "redirect",
    },
  },
  // 用户管理
  {
    path: "/system",
    element: <System />,
    meta: {
      key: "system",
    },
    children: [
      {
        path: "/system/user",
        element: <User />,
        meta: {
          key: "system_user",
        },
      },
      {
        path: "/system/userGroup",
        element: <UserGroup />,
        meta: {
          key: "system_user",
        },
      },
      {
        path: "/system/userGroup/:id",
        element: <UserGroupAdd />,
        meta: {
          key: "system_user",
        },
      },
      {
        path: "/system/authority",
        element: <Authority />,
        meta: {
          key: "system_authority",
        },
      },
      {
        path: "/system/role",
        element: <Role />,
        meta: {
          key: "system_role",
        },
      },
      // 税区管理
      {
        path: "/system/tax",
        element: <TaxManagement></TaxManagement>,
      },
      {
        path: "/system/tax/add",
        element: <AddTax></AddTax>,
      },
      // 编辑和详情
      {
        path: "/system/tax/:type/:id",
        element: <AddTax></AddTax>,
      },
      // 开票配置
      {
        path: "/system/invoiceSetting",
        element: <InvoiceSetting />,
      },
    ],
  },

  // 存证平台
  {
    path: "/evidence",
    element: <Evidence />,
    meta: {
      key: "evidence",
    },
    children: [
      // 客户列表
      {
        path: "/evidence/account/management",
        element: <Management />,
        meta: {
          key: "management",
        },
      },
      //客户关联存证
      {
        path: "/evidence/account/management/info/add_evidence",
        element: <AddEvi />,
        meta: {
          key: "addevi",
        },
      },
      // 模板列表
      {
        path: "/evidence/platform/template",
        element: <Template />,
        meta: {
          key: "template",
        },
      },
      // 字段列表
      {
        path: "/evidence/platform/field",
        element: <Fields />,
        meta: {
          key: "fields",
        },
      },
      {
        path: "/evidence/platform/database",
        element: <DataBase />,
      },
      {
        path: "/evidence/account/management/add",
        element: <CreateManagement />,
      },
      // 客户管理详情
      {
        path: "/evidence/account/management/manage_info",
        element: <ManagementInfo />,
        exact: true,
      },
      // 存证模板创建
      {
        path: "/evidence/platform/template/add",
        element: <AddTemplate />,
        exact: true,
      },
      // 字段模板创建和编辑
      {
        path: "/evidence/platform/field/add",
        element: <AddFeild />,
      },
      // 存证场景关联字段信息
      {
        path: "/evidence/platform/field/list",
        element: <FieldList />,
      },
    ],
  },
  // 代办业务
  {
    path: "/business",
    element: <Business />,
    children: [
      {
        path: "/business/administration",
        element: <Navigate to="/business/administration/list"></Navigate>,
      },
      {
        path: "/business/administration/list",
        element: <TaxAdministration></TaxAdministration>,
      },
      {
        path: "/business/logout/apply",
        element: <LogoutApply></LogoutApply>,
      },
      {
        path: "/business/logout/apply/add",
        element: <LogoutApplyAdd></LogoutApplyAdd>,
      },
      {
        path: "/business/administration/add",
        element: <AddAdministration></AddAdministration>,
      },
      {
        path: "/business/administration/newAdd",
        element: <NewAddAdministration></NewAddAdministration>,
      },
      {
        path: "/business/administration/edit/:id",
        element: <NewAddAdministration></NewAddAdministration>,
      },

      {
        path: "/business/ticket/list",
        element: <Ticket></Ticket>,
      },
      // 业务代办 税票系统 开票方列表
      {
        path: "/business/administration/info/:id",
        element: <InfoAdministration></InfoAdministration>,
      },
      {
        path: "/business/ticket",
        element: <Navigate to="/business/ticket/list"></Navigate>,
      },
      {
        path: "/business/ticket/list",
        element: <Ticket></Ticket>,
      },
      {
        path: "/business/agency",
        element: <Agency></Agency>,
      },
      {
        path: "/business/agency/info/:id",
        element: <AgencyCancellation></AgencyCancellation>, // 代办注销流程
      },
    ],
  },
  {
    path: "/consignor",
    element: <Consignor />,
    children: [
      {
        path: "/consignor/recover/management/:id",
        element: <RecoverManagement />,
      },
      {
        path: "/consignor/recover/list",
        element: <RecoverList />,
      },
    ],
  },
  // 客户管理
  {
    path: "/customer",
    element: <Customer />,
    children: [
      {
        path: "/customer/management",
        element: <CustomerManagement />,
      },
      {
        path: "/customer/management/add",
        element: <CustomerManagementAdd />,
      },
      {
        path: "/customer/management/info/:id/:current?",
        element: <CustomerInfo />,
      },
      {
        path: "/customer/:customerId/entity/add",
        // element: <AddEntity />,
        element: <CreateEntry></CreateEntry>,
      },
      {
        path: "/customer/:customerId/entity/:id/edit",
        element: <EditEntity />,
      },
      {
        path: "/customer/management/:customerId/relatedEntityDetail/:id/:isShowEdit",
        element: <RelatedEntityDetail />,
      },
    ],
  },
  {
    path: "/ticket",
    element: <Ticket />,
    children: [
      {
        path: "/ticket/invoicingParty",
        element: <InvoicingParty></InvoicingParty>,
      },
      {
        path: "/ticket/InvoicingTask",
        element: <InvoicingTask></InvoicingTask>,
      },
      {
        path: "/ticket/InvoicingTask/info/:id",
        element: <InvoicingTaskInfo />,
      },
      {
        path: "/ticket/openTask",
        element: <OpenTask />,
      },
      {
        path: "/ticket/ledger",
        element: <Ledger />,
      },
    ],
  },
  // 供应链金融
  {
    path: "/supply",
    element: <Supply></Supply>,
    children: [
      {
        path: "/supply/financingOrders",
        element: <FinancingOrders></FinancingOrders>,
      },
      {
        path: "/supply/financingOrders/info/:id",
        element: <FinancingOrdersInfo></FinancingOrdersInfo>,
      },
      {
        path: "/supply/financingProducts",
        element: <FinancingProduct />,
      },
      {
        path: "/supply/financingProducts/detail",
        element: <FinancingProductDetail />,
      },
      {
        path: "/supply/supplierAccess",
        element: <SupplyAccess></SupplyAccess>,
      },
      {
        path: "/supply/supplierAccess/detail/:id",
        element: <SupplyAccessDetail></SupplyAccessDetail>,
      },
      {
        path: "/supply/supplierAccess/info/:id",
        element: <SupplyAccessDetail></SupplyAccessDetail>,
      },
      {
        path: "/supply/supplierAccess/edit/:id",
        element: <SupplyAccessUpdate></SupplyAccessUpdate>,
      },
      {
        path: "/supply/enterpriseAccess",
        element: <EnterpriseAccess></EnterpriseAccess>,
      },
      {
        path: "/supply/enterpriseAccess/:id",
        element: <EnterpriseAccessDetail></EnterpriseAccessDetail>,
      },
    ],
  },
  // 实体交易
  {
    path: "/entity/transaction",
    element: <Entity></Entity>,
    children: [
      {
        path: "/entity/transaction/enterprise",
        element: <Enterprise></Enterprise>,
      },
      {
        path: "/entity/transaction/enterprise/:id",
        element: <EnterpriseDetail></EnterpriseDetail>,
      },
      {
        path: "/entity/transaction/individuality",
        element: <Individuality></Individuality>,
      },
      {
        path: "/entity/transaction/individuality/:id",
        element: <IndividualityDetail></IndividualityDetail>,
      },
      {
        path: "/entity/transaction/natural",
        element: <Natural></Natural>,
      },
      {
        path: "/entity/transaction/natural/:id",
        element: <NaturalDetail></NaturalDetail>,
      },
      // 订单
      {
        path: '/entity/transaction/procureOrder',
        element: <ProcureOrder></ProcureOrder>
      },
      {
        path: '/entity/transaction/procureOrder/:id',
        element: <NewPurchaseOrder />
      },
      // 平台品类
      {
        path: '/entity/transaction/platformClassification',
        element: <Classification></Classification>
      }
    ]
  },
];

const Element = () => {
  const loca = JSON.parse(
    localStorage.getItem("operation_authorization") || "{}"
  )?.access_token;
  const navigate = useNavigate();
  useEffect(() => {
    if (!loca) {
      navigate("/login");
    }
  }, [loca]);
  if (!loca) {
    const arr = [
      {
        path: "/login",
        element: <Login />,
        meta: {
          key: "login",
        },
      },
    ];
    const loginRoutes = useRoutes(arr);
    return loginRoutes;
  } else {
    const routes = useRoutes(routeData);
    return routes;
  }
};
export default Element;
