import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row, Input, Select, message } from "antd";
import {
  getRoleLists,
  getJobOrDepLists,
  editAccount,
} from "../../../../../../services/api/system/page/addUser";
import { RexIdNo } from "@/utils/RegExp";

import "./index.scss";

// type type_modal_operator = {
//     (a: boolean): void;
// }

// type type_user_modal = {
//     isEdit: boolean;
//     editModal: type_modal_operator;
//     modalData: {
//         id: string;
//         account?: string;
//         role?: string[];
//         department?: string;
//         email?: string;
//         name?: string;
//         position?: string;
//         roles?: {
//             name: string,
//             id: string
//         }[]
//         wxAccount?: string;
//         job?:any
//     };
//     cb: () => void
// }
const EditModal = (props: any) => {
  const { isEdit, editModal, cb, modalData } = props;
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();
  const [value, setValue] = useState<any>({});
  const [roleList, setRoleList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [jobs, setJobs] = useState([]);
  const handleCancel = () => {
    form.resetFields();
    editModal(false);
  };

  const onFinish = (values: any) => {
    const edditData = {
      id: modalData?.id,
      account: values.account,
      roleIds: values.role,
      department: values.department,
      email: values.email,
      name: values.name,
      position: values.job,
      wxAccount: values.wx,
      idNo: values.idNo,
    };

    editAccount(edditData).then((data) => {
      if (data.successful) {
        messageApi.open({
          type: "success",
          content: "编辑成功",
          onClose: () => {
            handleCancel();
            cb();
          },
        });
      } else {
        messageApi.open({
          type: "error",
          content: data.message || "编辑失败",
        });
      }
    });
  };
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  });
  useEffect(() => {
    getRoleLists().then((data) => {
      // const role = data.data.records.map((item: any) => (
      //     {
      //         name: item.name,
      //         id: item.id
      //     }
      // ))
      setRoleList(data?.data?.records);
      // setValue((value: any) => (
      //     {
      //         ...value,
      //         roles: role
      //     }
      // ))
    });
    getJobOrDepLists("DEPARTMENT").then((data) => {
      // setValue((value: any) => ({
      //     ...value,
      //     departments: data.data
      // }))
      setDepartmentsList(data.data);
    });
    getJobOrDepLists("POSITION").then((data) => {
      // setValue((value: any) => ({
      //     ...value,
      //     jobs: data.data
      // }))
      setJobs(data.data);
    });
  }, []);

  useEffect(() => {
    setValue({
      name: modalData?.name,
      job: modalData?.job,
      jobs: [],
      department: modalData?.department,
      departments: [],
      email: modalData!.email,
      wx: modalData!.wxAccount,
      account: modalData!.account,
      role: modalData?.roleIdList,
      roles: modalData?.roles,
      idNo: modalData?.idNo,
    });
  }, [modalData]); // eslint-disable-line

  return (
    <>
      <Modal
        open={isEdit}
        title="用户编辑"
        onCancel={handleCancel}
        centered
        width={600}
        footer={null}
      >
        {contextHolder}
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={value}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: "请输入！" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="职位"
                name="job"
                rules={[{ message: "最多50个字", max: 50 }]}
              >
                <Input />
                {/* <Select>
                                    {
                                        jobs.map((item: any) => (
                                            <Select.Option key={item} value={item}>{item}</Select.Option>))
                                    }
                                </Select> */}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="部门"
                name="department"
                rules={[{ message: "最多50个字", max: 50 }]}
              >
                <Input />
                {/* <Select>
                  {departmentsList.map((item: any) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="邮箱"
                name="email"
                rules={[
                  { required: false, message: "请输入！" },
                  {
                    pattern:
                      /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,

                    message: "邮箱格式不正确",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="账号"
                name="account"
                rules={[
                  { required: true, message: "请输入！" },
                  { pattern: /^1[0-9]{10}/, message: "请输入手机号格式的账号" },
                ]}
              >
                <Input maxLength={11} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="角色"
                name="role"
                rules={[{ required: true, message: "请输入！" }]}
              >
                <Select
                  mode="multiple"
                  defaultValue={modalData?.roleIdList || []}
                >
                  {roleList.map((item: any) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                  {/* {
                                        value!.roles!.map((item: any) => (
                                            <Select.Option key={item.name} value={item.id}>{item.name}</Select.Option>))
                                    } */}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="企业微信账号"
                name="wx"
                // rules={[{ message: '请输入！' }, { pattern: new RegExp(/^[a-zA-Z\d]+$/), message: '请输入正确格式' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="身份证"
                name="idNo"
                rules={[{ pattern: RexIdNo, message: "请输入正确身份证格式" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col offset={8} span={4}>
              <Form.Item wrapperCol={{ span: 16 }}>
                <Button
                  onClick={(e) => {
                    handleCancel();
                  }}
                >
                  取消
                </Button>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item wrapperCol={{ span: 16 }}>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default EditModal;
