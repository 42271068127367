/**
 * @author 阮东辉
 * @time 2023/3/15
 * @type 图片/视频/音频
 * */ 
import React, { useEffect, useState, useImperativeHandle,  forwardRef, useRef } from "react";
import { Form, Input,  Radio, Checkbox, InputNumber, Row, Col, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './index.scss'
import { useDispatch} from 'react-redux'
import {UPDATE_BASIC, UPDATE_RULE} from '../../../../store/constants'
const FormItem = Form.Item
/**
 * @param{type} string 区别是限制条件或者基本类型数据 
 * */ 

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 24,
  },
};
const radioList = [
  {
    label:'读写',
    value:'WRITE'
  },
  {
    label:'只读',
    value:'READ'
  },
  {
    label:'隐藏',
    value:'HIDE'
  }
]

// 输入内容
const inputContentList = [
  {
    label:'小写字母',
    value:'LOWER_CASE'
  },{
    label:'数字',
    value:'NUMBER'
  },{
    label:'大写字母',
    value:'UPPER_CASE'
  },{
    label:'汉字',
    value:'CHINESE'
  },
]

const uuid = ()=> {
  let date=(new Date()).valueOf();//获取时间戳
  let txt = '1234567890';//生成的随机机器码
  let len =13;//机器码有多少位
  let pwd = '';//定义空变量用来接收机器码
  for (let i = 0; i < len; i++) {
    pwd += txt.charAt(Math.floor(Math.random() * txt.length));//循环机器码位数随机填充
  }
  return date+pwd;
}
// 动态创建表单
const FormCreate = (props:any) => {
  const {name,label, cb, defaultValue, isInfo} = props
  const [list, setList] = useState([
    {
      value:'',
      id: uuid()
    }
  ])
  useEffect(() => {
    if(defaultValue?.option && defaultValue?.option.length !== 0){
      const res = defaultValue.option.map((item:any) => {
        return {
          value: item,
          id:uuid()
        }
      })
      setList(res)
    }else{
      setList([{
        value:'',
        id: uuid()
      }])
    }
  }, [defaultValue])
  const handleCreate = () => {
    let arr = JSON.parse(JSON.stringify(list))
    arr.push({
      value:'',
      id: uuid()
    })
    setList(arr)
  }
  const handleReset =() => {
    var arr =[]
    arr.push({
      value:'',
      id:uuid()
    })
    setList(arr)
  }
  const remove = (object:any) => {
    const arr = JSON.parse(JSON.stringify(list))
    const res = arr.filter((item:any) => item.id !== object.id)
    setList(res)
  }
  const handleInput = (e:any, object:any) => {
    const id = e.target.id;
    const value = e.target.value;
    const arr = JSON.parse(JSON.stringify(list))
    const res = arr.map((item:any) => {
      if(item.id === id){
        item.value = value
      }
      return item
    })
    setList(res)
  }
  useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
    cb && cb(list)
  }, [list]) // eslint-disable-line
  return <Form.Item name={name} noStyle>
    <div className="ipt_col">
      <span>
        {label}：
      </span>
      
      <div style={{display:'inline-block'}}>
      {
        list.map((item, index) => {
          return <div style={{ marginBottom:'10px'}}>
            <Input disabled={isInfo} key={index} style={{width:'200px'}} onChange={(e) => handleInput(e, item)} id={item.id} value={item.value}></Input>
            {
              list.length >1 && !isInfo ?<Button type='text' icon={<MinusCircleOutlined style={{color:'red', }}/>} onClick={() => remove(item)}></Button> : null
            }
              
            </div>
          })
        }
        {
          !isInfo && <>
          <Button
          type="link"
          onClick={handleCreate}
          icon={<PlusOutlined />}
        >
          添加选项
        </Button>
        <Button
          type="link"
          onClick={handleReset}
        >
          重置
        </Button>
          </>
        }
        
      </div>
    </div>
  </Form.Item>
}

const FileTemplate = (props:any, ref:any) => {
  const {infoType = 'basicInfo', infoData, type, isInfo} = props
  const [formBacis] = Form.useForm();
  const [formCondition] = Form.useForm()
  const [formCoordinate] = Form.useForm()
  const dispatch = useDispatch<any>()
  const [basic, setBasic] = useState<any>({})
  // rules
  const [rules, setRules] = useState<any>({})
// 本地存贮 最多最少 infoData?.rules?.limitInputWordQuantity?.maxValue
  const numbeRef=useRef({
    minValue:'',
    maxValue:''
  })
  const maxRef = useRef({
    minValue:'',
    maxValue:''
  })
  // 本地缓存数据
  const [local, setLocal] = useState<any>({})
  const updateState = ( type:string, value:any) => {
    if(infoType === 'basicInfo'){
      setBasic({
        ...basic,
        [type]:value
      })
      dispatch({
        type:UPDATE_BASIC, payload:{
          [type]:value
        }
      })
    }
    if(infoType === 'condition'){
      setRules({
        ...rules,
        [type]:value
      })
      dispatch({
       type:UPDATE_RULE, payload:{
          [type]:value
        }
      })
    }
  }  
  const reset= () => {
    setTimeout(() => {
    formBacis.resetFields()
    formCondition.resetFields()
    },100)

  }
  const formValidate = () => {
    formBacis.validateFields()
    formCondition.validateFields()
    formCoordinate.validateFields()
  }
  useImperativeHandle(ref, () =>({
    reset,
     formValidate
  }))
  useEffect(() => {
    reset()
    numbeRef.current.minValue=''
    numbeRef.current.maxValue=''
    maxRef.current.minValue=''
    maxRef.current.maxValue=''

  }, [type]) // eslint-disable-line
  useEffect(() => {
    if(infoData?.name && infoData?.name !==''){
      formBacis.setFieldValue('name', infoData.name)
      // dispatch({
      //   type:UPDATE_BASIC, payload:{
      //     name:infoData?.name
      //   }
      // })
      if(infoData.componentType === 'COORDINATE'){
        formCoordinate.setFieldValue('name', infoData.name)
  
      }
    }
    if(infoData.id && infoData.id !== undefined){
    if(!local?.id || local?.id !== infoData?.id){
      setLocal(infoData)
      numbeRef.current.minValue = infoData?.rules?.limitInputWordQuantity?.minValue || ''
      numbeRef.current.maxValue = infoData?.rules?.limitInputWordQuantity?.maxValue || ''
      maxRef.current.minValue = infoData?.rules?.limitInputValueRange?.minValue || ''
      maxRef.current.maxValue = infoData?.rules?.limitInputValueRange?.maxValue || ''
    }
  }
  }, [infoData])// eslint-disable-line
  
    if(infoType === 'basicInfo'){
      if(type === 'coordinate'){
        return <Form {...layout} form={formCoordinate}>
          <FormItem name='name' label='字段名称' rules={[
              {
                required: true, message:'请输入字段名称'
              }
            ]}>
              <Input  maxLength={10} disabled={isInfo} className="ipt"defaultValue={infoData?.name} onChange={(e) => {
                updateState( 'name', e.target.value)
                formCoordinate.setFieldValue('name', e.target.value)
              }}></Input>
              <span>不超过10个字，支持中文，英文，数字，字母组合</span>
            </FormItem>
        </Form>
      }else
      return <div>
        <Form {...layout} form={formBacis} name='money_form'>
          <FormItem name='name' label='字段名称' rules={[
            {
              required: true, message:'请输入字段名称'
            }
          ]}>
            <Input disabled={isInfo} className="ipt"defaultValue={infoData?.name} onChange={(e) => {
              updateState( 'name', e.target.value)
              formBacis.setFieldValue('name', e.target.value)
            }}></Input>
            <span>不超过10个字，支持中文，英文，数字，字母组合</span>
          </FormItem>
          <FormItem label='默认值' name='dataValue'>
            <Input disabled={isInfo}  defaultValue={infoData?.defaultValue?.dataValue} className="ipt" onChange={(e) => {
              updateState( 'dataValue', e.target.value)
            }}></Input>
            <span>按照限制条件展示，若与限制条件不符，则不展示</span>
          </FormItem>
          <Row  justify="center">
            <Col span='12'>
              <FormCreate defaultValue={infoData?.rules?.textPrefixOption} name='textPrefixOption' label='文本前置下拉配置' cb={(values:any) => {
                const res = values.map((item:any) => item.value)
                updateState('textPrefixOption', {
                  option:res
                })
              }} isInfo={isInfo}></FormCreate>
            </Col>
            <Col span='12'>
              <FormCreate defaultValue={infoData?.rules?.textSuffixOption} name='textSuffixOption' label='文本后置下拉配置' cb={(values:any) => {
                const res = values.map((item:any) => item.value)
                updateState('textSuffixOption', {
                  option:res
                })
              }} isInfo={isInfo}></FormCreate>
            </Col>
          </Row>
          <FormItem label='输入正则表达式' name='regularExpression'>
            <Input disabled={isInfo} defaultValue={infoData?.rules?.regularExpression} className="ipt" placeholder="请输入默认字段" onChange={(e:any) => {
              updateState('regularExpression', e.target.value)
            } }></Input>
          </FormItem>
          <FormItem label='用户权限' name='accessType'>
            <Radio.Group disabled={isInfo} defaultValue={infoData?.accessType} onChange={(e) => {
              updateState('accessType', e.target.value)
            }}>
              {radioList.map((item:any) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)}
            </Radio.Group>
            <span>读写:允许用户查看和编辑该字段;只读:用户只能查看该字段;隐藏:用户无法查看该字段</span>
          </FormItem>
          <FormItem label='占位内容' name='placeholder'>
            <Input disabled={isInfo} defaultValue={infoData?.placeholder} className="ipt" onChange={(e) => {
              updateState('placeholder', e.target.value)
            }}></Input>
            <span>占位内容,显示在输入框中每用户点击输入框或有内容输入后消失</span>
          </FormItem>
          <FormItem label='字段说明' name='comment'>
            <Input disabled={isInfo} className="ipt" defaultValue={infoData?.comment}  onChange={e => {
              updateState('comment', e.target.value)
            }}></Input>
            <span>字段说明,显示在字段输入框之后(移动端在输入框下方),用于提示输入框及规则</span>
          </FormItem>
        </Form>
      </div>
    }else{
      if(type === 'coordinate') return null
      return <div>
        <Form {...layout} form={formCondition}>
        <FormItem label={'限制输入字数'}>
            <span>
            
              最少: <InputNumber disabled={isInfo} defaultValue={infoData?.rules?.limitInputWordQuantity?.minValue} max={numbeRef.current.maxValue} 
              value={numbeRef.current.minValue}
              onChange={(e) => {
                numbeRef.current.minValue= e
                let obj ={}
                if(maxRef.current.minValue !== '' || maxRef.current.maxValue !== ''){
                  obj = {
                    ...maxRef.current
                  }
                }
                dispatch({
                  type:UPDATE_RULE, payload:{
                    limitInputValueRange:obj,
                    limitInputWordQuantity:{
                      ...numbeRef.current,
                      minValue:e
                    }
                  }
                })
              }}></InputNumber> 
            </span>
              
            <span>  最多: <InputNumber disabled={isInfo} defaultValue={infoData?.rules?.limitInputWordQuantity?.maxValue} min={numbeRef.current.minValue} 
            value={numbeRef.current.maxValue}
            onChange={(e) => {
              numbeRef.current.maxValue= e
              let obj ={}
                if(maxRef.current.minValue !== '' || maxRef.current.maxValue !== ''){
                  obj = {
                    ...maxRef.current
                  }
                }
                // updateState('limitInputWordQuantity', {
                //   // ...rules.limitInputWordQuantity,
                //   // ...curData,
                //   // ...selector.ruleForm.limitInputWordQuantity,
                //   maxValue: e
                // })
                dispatch({
                  type:UPDATE_RULE, payload:{
                    limitInputValueRange:obj,
                    limitInputWordQuantity:{
                      ...numbeRef.current,
                      maxValue: e
                    }
                  }
                })
              }}></InputNumber> </span> 
        </FormItem>
        <FormItem label={'允许输入内容'}>
          <Checkbox.Group disabled={isInfo} defaultValue={infoData?.rules?.limitInputType?.contentType} options={inputContentList} onChange={(e) => {
            updateState('limitInputType', {
              contentType: e
            })
          }}></Checkbox.Group>
        </FormItem>
        {
          type === 'money' || type ==='weight' ? <FormItem label={'限制值范围'}>
            <span>
              最小: <InputNumber disabled={isInfo} defaultValue={infoData?.rules?.limitInputValueRange?.minValue} value={maxRef.current.minValue} max={maxRef.current.maxValue} onChange={(e) => {
                
                maxRef.current.minValue= e
                let obj ={}
                if(numbeRef.current.minValue !== '' || numbeRef.current.maxValue !== ''){
                  obj = {
                    ...numbeRef.current
                  }
                }
                dispatch({
                  type:UPDATE_RULE,
                  payload:{
                    limitInputWordQuantity:obj,
                    limitInputValueRange:{
                      ...maxRef.current,
                      minValue:maxRef.current.minValue
                    }
                  }
                })

              }}></InputNumber> 
            </span>
              
            <span>  最大: <InputNumber disabled={isInfo} 
            defaultValue={infoData?.rules?.limitInputValueRange?.maxValue}
            value={maxRef.current.maxValue} min={maxRef.current.minValue}
            onChange={(e) => {
              // if(e < maxRef.current.minValue){
              //   maxRef.current.maxValue= maxRef.current.minValue
              // }else{
              //   maxRef.current.maxValue= e
              // }
              maxRef.current.maxValue= e
              let obj ={}
                if(numbeRef.current.minValue !== '' || numbeRef.current.maxValue !== ''){
                  obj = {
                    ...numbeRef.current
                  }
                }
              dispatch({
                type:UPDATE_RULE,
                payload:{
                  limitInputWordQuantity:obj,
                  limitInputValueRange:{
                    ...maxRef.current,
                    maxValue:maxRef.current.maxValue
                  }
                }
              })
            }}></InputNumber> </span> 
        </FormItem> : null
        }
       {
        type === 'money' || type ==='weight' ?<FormItem label={'限制精度' }>
          小数点后: <InputNumber disabled={isInfo} min={0}
            defaultValue={infoData?.rules?.limitPrecision?.precision}
           onChange={e => {
            updateState('limitPrecision', {
              precision: e
            })
          }} ></InputNumber> 
        </FormItem>: null
       }
        

        </Form>
      </div>
    }
}

export default forwardRef(FileTemplate) 




