import { FC, HTMLAttributes, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Tooltip, TooltipProps } from 'antd';

const defaultProps = {
  mode: 'single',
};

type Props = { width?: number; tooltipProps?: TooltipProps } & (
  | {
      mode?: 'single'; line?: number
    }
  | { mode?: 'multiple'; line?: number }
);

type OverflowEllipsisTextProps = Partial<typeof defaultProps> & Props & HTMLAttributes<any>;
const OverflowEllipsisText: FC<PropsWithChildren<OverflowEllipsisTextProps>> = props => {
  const { className, width, style, mode, line, tooltipProps, ...rest } = {
    ...defaultProps,
    ...props,
  };
  const elRef = useRef<HTMLSpanElement>(null);
  const [isMoreMaximum, setIsMoreMaximum] = useState(false);
  const ellipsisStyle =
    mode === 'multiple'
      ? {
          maxWidth: width,
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': line,
          overflow: 'hidden',
        }
      : {
          maxWidth: width,
          'white-space': 'nowrap',
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
        };
  useEffect(() => {
    if (elRef.current?.getBoundingClientRect().width && width) {
      setIsMoreMaximum(elRef.current?.getBoundingClientRect().width >= width);
    }
  });
  return (
    <Tooltip {...tooltipProps} title={isMoreMaximum && (tooltipProps?.title || rest.children)}>
      <span
        ref={elRef}
        {...rest}
        className={classNames(className)}
        style={{ ...ellipsisStyle, display: 'inline-block', ...style }}
      />
    </Tooltip>
  );
};

export default OverflowEllipsisText;
