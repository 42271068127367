import React, { memo, useEffect, useMemo } from "react";
import ProDescriptions, {
  ProDescriptionsItemProps,
  ProDescriptionsProps,
} from "@ant-design/pro-descriptions";
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header";
import { DetailPageContent } from "@src/components/DetailPageComponents/Content";
import { Card, Space } from "antd";
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer";
import { OperableImage } from "@src/components/OperableImage";
import { PaperClipOutlined } from "@ant-design/icons";
import { InvoiceItemTable } from "../../components/InvoiceItemTable";
import { Link, useParams } from "react-router-dom";
import { pick } from "lodash";
import {
  EntityDetailContext,
  useEntityDetailLogic,
} from "../../hooks/useEntityDetailLogic";
import {
  EntityStatusEnum,
  EntityTypeEnum,
  commonHeaderDetail,
  invoiceInfoColumns,
} from "../../constant";
import { EntityDetailPageContainer } from "../../components/EntityDetailPageContainer";
import { RexIdNo, RexSocialCreditCode } from "@src/utils/RegExp";
import "./index.scss";

const defaultProps = {};
type props = {};
export type EnterpriseDetailProps = Required<typeof defaultProps> & props;
const arr = ["sourceType", "createBy"] as const;
type a = (typeof arr)[number];
const get =
  <T extends Record<string, any>>(obj: T) =>
  (keys: keyof T) => {
    return pick(obj, keys);
  };

// type OnSave = Exclude<Exclude<ProDescriptionsProps<RecordType>['editable'],undefined>['onSave'],undefined>
type OnSave = Exclude<
  Exclude<
    ProDescriptionsProps<Partial<API.Entity.Info>>["editable"],
    undefined
  >["onSave"],
  undefined
>;
export const EnterpriseDetail: React.FC<EnterpriseDetailProps> = memo(
  (props) => {
    const entityDetailService = useEntityDetailLogic();
    const { isCRM } = entityDetailService;
    useEffect(() => {
      if (entityDetailService.entityDataService.data) {
        entityDetailService.fileUrlService.run([
          entityDetailService.entityDataService.data.license,
          entityDetailService.entityDataService.data.contractFileId,
        ]);
      }
    }, [entityDetailService.entityDataService.data]);
    const [licenseUrl, contractUrl] =
      entityDetailService.fileUrlService.data || [];
    const headerDescColumns: ProDescriptionsItemProps<API.Entity.Info>[] = [
      ...commonHeaderDetail.slice(0, 3),
      {
        dataIndex: "sourceName",
        title: "来源名称",
        render: (dom, record) =>
          isCRM() ? (
            <Link to={`/customer/management/info/${record.sourceId}`}>
              {dom}
            </Link>
          ) : (
            dom
          ),
        editable: false,
      },
      { dataIndex: "createUserAccount", title: "创建账号", editable: false },
      {
        dataIndex: "status",
        title: "实体状态",
        renderText: (v) => v,
        valueEnum: EntityStatusEnum,
      },
      { dataIndex: "createTime", title: "创建时间", editable: false },
    ];

    const basicInfoColumns: ProDescriptionsItemProps<API.Entity.Info>[] = [
      {
        dataIndex: "name",
        title: "企业名称",
        copyable: true,
        formItemProps: { rules: [{ message: "请输入", required: true }] },
      },
      {
        dataIndex: "taxNo",
        title: "统一信用代码",
        copyable: true,
        formItemProps: {
          rules: [
            { message: "请填写正确格式", pattern: RexSocialCreditCode },
            { message: "请输入", required: true },
          ],
        },
      },
      {
        dataIndex: "legalPerson",
        title: "法人",
        formItemProps: { rules: [{ message: "请输入", required: true }] },
      },
      {
        dataIndex: "idNumber",
        title: "身份证号",
        formItemProps: {
          rules: [
            { message: "请填写正确格式", pattern: RexIdNo },
            { message: "请输入", required: true },
          ],
        },
      },
      {
        dataIndex: "taxRegionIds",
        title: "合作税区",
        renderText: (value) => value?.split(","),
        valueType: "select",
        fieldProps: {
          mode: "multiple",
          options: entityDetailService.taxOptions,
        },
      },
      {
        dataIndex: "contractFileId",
        title: "合同",
        editable: false,
        renderText: (value) => value,
        render: (value, record) =>
          record.contractFileId ? (
            <Space size="small">
              <PaperClipOutlined />{" "}
              <a href={contractUrl} target="_blank">
                合同.pdf
              </a>
            </Space>
          ) : (
            <>-</>
          ),
      },
      {
        dataIndex: "license",
        title: "营业执照",
        editable: false,
        render: (dom, record) => (
          <>
            <OperableImage
              filename="营业执照"
              width={104}
              height={104}
              uploadFileProps={{
                onChange: entityDetailService.onUploadFileChange.bind(
                  null,
                  "license"
                ),
              }}
              src={licenseUrl}
            ></OperableImage>
          </>
        ),
      },
    ];

    return (
      <EntityDetailPageContainer value={entityDetailService}>
        <DetailPageHeader
          breadCrumb={[
            { label: "交易实体" },
            { label: "企业" },
            // { label: entityDetailService.entityDataService.data?.name || "" },
            { label: "企业名称详情" },
          ]}
          title={entityDetailService.entityDataService.data?.name}
        >
          <ProDescriptions
            editable={{ onSave: entityDetailService.onSave }}
            columns={headerDescColumns}
            dataSource={entityDetailService.entityDataService.data}
          ></ProDescriptions>
        </DetailPageHeader>
        <DetailPageContent>
          <Card title="基本信息">
            <ProDescriptions
              editable={{ onSave: entityDetailService.onSave }}
              columns={basicInfoColumns}
              dataSource={entityDetailService.entityDataService.data}
            ></ProDescriptions>
          </Card>
          <Card title="开票信息">
            <ProDescriptions
              editable={{ onSave: entityDetailService.onSave }}
              columns={invoiceInfoColumns}
              dataSource={entityDetailService.entityDataService.data}
            ></ProDescriptions>
            <InvoiceItemTable />
          </Card>
        </DetailPageContent>
      </EntityDetailPageContainer>
    );
  }
);
EnterpriseDetail.defaultProps = defaultProps;
