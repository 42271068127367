import { DetailPageContent } from "@src/components/DetailPageComponents/Content";
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header";
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer";
import CoreEPSignerFormFields from "@src/components/FormTemplate/CoreEPSignerFormFields";
import ModalForm from "@src/components/ModalForm";
import { TableToolBar } from "@src/components/TableToolBar";
import { PushChannelMap } from "@src/constant/supplyChainFinance";
import {
  CreateSignerForCoreEnterpriseResData,
  getCoreEnterpriseDetail,
  postCreateSignerForCoreEnterprise,
  postDeleteSignerForCoreEnterprise,
  postUpdateSignerForCoreEnterprise,
} from "@src/services/api/supplyChainFinance/coreEnterprise";
import { checkValueToContinue, confirmToContinue } from "@src/utils/utils";
import { useRequest } from "ahooks";
import { Button, Card, Descriptions, Space, Table, message } from "antd";
import React, { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
const pushTypeMap = {
  SIGN: '签署通知',
  REPAYMENT: '还款通知'
}
export const EnterpriseAccessDetail: React.FC = memo(() => {
  const searchParams = useParams();
  const CoreEnterpriseDetail = useRequest(getCoreEnterpriseDetail, {
    manual: true
  });
  useEffect(() => {
    if (searchParams?.id) CoreEnterpriseDetail.run(searchParams?.id);
  }, []);
  return (
    <DetailPageContainer>
      <DetailPageHeader
        breadCrumb={[{ label: "供应链金融" }, { label: "核心企业准入" }, { label: "详情" }]}
      >
        <Descriptions>
          <Descriptions.Item label={"企业名称"}>
            {CoreEnterpriseDetail.data?.name}
          </Descriptions.Item>
          <Descriptions.Item label={"核心企业证件号"}>
            {CoreEnterpriseDetail.data?.coreEnterpriseId}
          </Descriptions.Item>
          <Descriptions.Item label={"滑动六个月平均开票交易额"}>
            {CoreEnterpriseDetail.data?.slidingTurnover}
          </Descriptions.Item>
        </Descriptions>
      </DetailPageHeader>
      <DetailPageContent>
        <Card title={"推送信息"}>
          <TableToolBar
            title={"配置用来接收消息的地址"}
            action={
              <ModalForm<CreateSignerForCoreEnterpriseResData>
                modalProps={{ title: "担保人新增", destroyOnClose: true }}
                trigger={<a>新增</a>}
                onFinish={async (values) => {
                  await checkValueToContinue(CoreEnterpriseDetail.data?.id);
                  await postCreateSignerForCoreEnterprise({
                    ...values,
                    coreEnterpriseId: CoreEnterpriseDetail.data?.id!,
                  });
                  message.success("添加成功");
                  CoreEnterpriseDetail.refresh();
                }}
              >
                <CoreEPSignerFormFields />
              </ModalForm>
            }
          />
          <Table<API.Schema.SignerPushVo>
            dataSource={CoreEnterpriseDetail.data?.signerPushs}
            columns={[{
              title: "消息类型",
              dataIndex: "pushType",
              width: 350,
              render: (t) => pushTypeMap?.[t]
            },
            {
              title: "证件号（企业证件号、身份证号）",
              dataIndex: "certCard",
              width: 350,
            },
            {
              title: "推送渠道",
              dataIndex: "pushChannel",
              width: 200,
              render: (channel) => {
                return PushChannelMap[channel] || "其他";
              },
            },
            {
              title: "推送地址",
              dataIndex: "pushAddress",
              render: (text) => {
                return <span style={{ wordBreak: 'break-word' }}>{text}</span>
              }
            },
            {
              title: "操作",
              dataIndex: "actions",
              width: 150,
              render: (_, record) => {
                return (
                  <Space>
                    <ModalForm<CreateSignerForCoreEnterpriseResData>
                      modalProps={{ title: "担保人编辑", destroyOnClose: true }}
                      fieldProps={{
                        initialValues: record
                      }}
                      trigger={<a>编辑</a>}
                      onFinish={async (values) => {
                        await checkValueToContinue(
                          CoreEnterpriseDetail.data?.id && record.id
                        );
                        await postUpdateSignerForCoreEnterprise({
                          ...values,
                          coreEnterpriseId: CoreEnterpriseDetail.data?.id!,
                          signerPushId: record.id!,
                        });
                        message.success("修改成功");
                        CoreEnterpriseDetail.refresh();
                      }}
                    >
                      <CoreEPSignerFormFields />
                    </ModalForm>

                    <a
                      onClick={async () => {
                        await confirmToContinue({ title: "提示", content: "是否删除该担保人？" })
                        await checkValueToContinue(
                          CoreEnterpriseDetail.data?.id && record.id
                        );
                        await postDeleteSignerForCoreEnterprise({
                          coreEnterpriseId: CoreEnterpriseDetail.data?.id!,
                          signerPushId: record.id!,
                        });
                        message.success("删除成功");
                        CoreEnterpriseDetail.refresh();
                      }}
                    >
                      删除
                    </a>
                  </Space>
                );
              },
            },
            ]}
          ></Table>
        </Card>
      </DetailPageContent>
    </DetailPageContainer>
  );
});
EnterpriseAccessDetail.displayName = "核心企业详情";
export default EnterpriseAccessDetail;
