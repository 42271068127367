import React, { useEffect, useState, useMemo } from "react";
import { Button, Row, Col, Tag } from "antd";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import TableCom from "@src/components/TableCom";
import SearchForm from "@src/components/FormItem";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import styles from "./index.module.scss";
import { getOrderInfo } from "../../promise";
import { repaymentModeObj, financingStatusOptions } from "../config";
const breads = [
  {
    label: "融资订单",
    path: "",
  },
  {
    label: "详情",
    path: "/supply",
  },
];
const FinancingOrdersInfo = () => {
  const searchParams = useParams();
  const [info, setInfo] = useState<any>({});
  const statusName = useMemo(() => {
    const obj = financingStatusOptions.find(
      (item) => item.value === info?.orderStatus
    );
    return obj?.label;
  }, [info]);
  const getInfo = async (id: any) => {
    if (!id) return;
    const res = await getOrderInfo(id);
    if (res?.code === 200) {
      setInfo(res?.data);
    }
  };
  useEffect(() => {
    getInfo(searchParams?.id);
  }, [searchParams]);
  const columns = [
    {
      title: "还款日期",
      dataIndex: "repaymentDate",
    },
    {
      title: "本金",
      dataIndex: "principal",
    },
    {
      title: "利息",
      dataIndex: "interest",
    },
    {
      title: "本息总额",
      dataIndex: "principalInterest",
      // render: (record: any) => {
      //     const res =
      //         Number(record?.principalInterest || 0) +
      //         Number(record?.penaltyInterest || 0).toFixed(2);
      //     return <span>{res}</span>;
      // },
    },
    {
      title: "罚息",
      dataIndex: "penaltyInterest",
    },
    {
      title: "应还总额",
      // dataIndex: "principalInterest",
      render: (record: any) => {
        const res = (
          Number(record?.principalInterest || 0) +
          Number(record?.penaltyInterest || 0)
        ).toFixed(2);
        return <span>{res}</span>;
      },
    },
    {
      title: "已还总额",
      dataIndex: "repaidAmount",
    },
    {
      title: "剩余应还",
      dataIndex: "remainingAmount",
    },
  ];
  console.log(info, "info");
  return (
    <div className={styles.supply_order_container}>
      <BreadCrumbBar breads={breads}></BreadCrumbBar>
      <div className={styles.supply_order_info}>
        <div className={styles.title}>申请信息</div>
        <div className={styles.info_values}>
          <Row>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>融资单号：</span>
                {info?.financeNo}
              </div>
              <div className={styles.info_items}>
                <span className={styles.label}>关联订单：</span>
                {info?.xzswOrderIds ? info?.xzswOrderIds.toString() : ""}
              </div>
              <div className={styles.info_items}>
                <span className={styles.label}>借款金额：</span>
                {info?.principal}
              </div>
              <div className={styles.info_items}>
                <span className={styles.label}>收款账号：</span>
                {info?.payeeAccount}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>融资产品：</span>
                {info?.productName}
              </div>
              <div className={styles.info_items}>
                <span className={styles.label}>借款人：</span>
                {info?.supplierAccess?.name}
              </div>
              <div className={styles.info_items}>
                <span className={styles.label}>借款周期：</span>
                {info?.loanCycle}天
              </div>
              <div className={styles.info_items}>
                <span className={styles.label}>申请时间：</span>
                {info?.applyTime}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>融资公司：</span>
                {info?.financeCompany}
              </div>
              <div className={styles.info_items}>
                <span className={styles.label}>核心企业：</span>
                {info?.coreEnterpriseName}
              </div>
              <div className={styles.info_items}>
                <span className={styles.label}>借款利率：</span>
                年利率{info?.annualInterestRate}%
              </div>
              <div className={styles.info_items}>
                <span className={styles.label}>状态：</span>
                {statusName}
                {/* orderStatus */}
              </div>
            </Col>
          </Row>
        </div>
        <div className={`${styles.title} ${styles.mar_top_16}`}>放款信息</div>
        <div className={styles.info_values}>
          <Row>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>放款金额：</span>
                {info?.loanTime ? info?.principal : ""}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>放款时间：</span>
                {info?.loanTime}
              </div>
            </Col>
          </Row>
        </div>
        <div className={`${styles.title} ${styles.mar_top_16}`}>
          还款信息 <Tag color="error">不支持提前还款</Tag>
        </div>
        <div className={styles.info_values}>
          <Row>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>还款模式：</span>
                {repaymentModeObj[info?.repaymentMode]}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>还款账号：</span>
                {info?.repaymentAccount}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>账户名：</span>
                {info?.repaymentName}
              </div>
            </Col>
            <Col span="8">
              <div className={styles.info_items}>
                <span className={styles.label}>开户行：</span>
                {info?.repaymentBank}
              </div>
            </Col>
          </Row>
          <div className={styles.mar_top_16}>
            <TableCom
              columns={columns}
              dataSource={[{ ...info }]}
              total={0}
              pagination={false}
              scroll={{
                x: true,
              }}
            ></TableCom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancingOrdersInfo;
