import { GOODS } from "@src/constant/goods";
import numeral from "numeral";

export function convertData(
  orderDetail?: API.Schema.OrderDetail,
  supplyContracts?: API.Schema.SupplyContractItem[],
  entities?: {seller?: API.Entity.Info,buyer?: API.Entity.Info}
) {
  // @ts-ignore
  const localData: Partial<API.Order.Info> = {
    ...(orderDetail
      ? {
          id: orderDetail?.id.toString(),
          channelName: orderDetail.channelName,
          orderNo: orderDetail.orderNo,
          paymentStatus: orderDetail?.paymentStatus,
          unitOfMeasureShow: GOODS[orderDetail?.unitOfMeasure]||orderDetail?.unitOfMeasure,
          unitOfMeasure: orderDetail?.unitOfMeasure,
          subChannel: orderDetail.subChannel,
          supplier:entities?.seller? {
            id: entities.seller.id,
            idNumber: entities.seller.idNumber,
            name: orderDetail?.sellerName,
            type: entities.seller.type,
            taxNo: entities?.seller.taxNo, // 根据实际情况填充
          }:undefined,
          buyer: entities?.buyer?{
            id: entities.buyer.id,
            name: orderDetail?.buyerName,
            type: entities.buyer.type,
            taxNo: entities?.buyer.taxNo, // 根据实际情况填充
          }:undefined,
          merchandises: [
            {
              unitOfMeasure: GOODS[orderDetail?.unitOfMeasure]||orderDetail?.unitOfMeasure as API.unitOfMeasure,
              name: orderDetail.buyerCategoryName,
              quantity: orderDetail?.quantity?.toString(),
              unitPrice: orderDetail?.unitPrice,
              totalPrice: orderDetail?.beforeVatAmt,
              vatAmount: orderDetail?.vatAmt,
              totalAmount: orderDetail?.afterVatAmt,
            },
            // 可根据需要添加更多 merchandise 转换
          ],
          differentialAmount: orderDetail.inputAward,
          contract: {
            supplier: orderDetail.sellSigner?{
              idCard: orderDetail.sellSigner.idCard,
              signer: orderDetail.sellSigner.name,
              phone: orderDetail.sellSigner.phone,
              signStatus: ['SELLER_COMPLETED','ALL_COMPLETED'].includes(orderDetail.contractStatus),
            }:undefined,
            buyer: orderDetail.buySigner?{
              idCard: orderDetail.buySigner.idCard,
              signer: orderDetail.buySigner.name,
              phone: orderDetail.buySigner.phone,
              signStatus: ['BUYER_COMPLETED','ALL_COMPLETED'].includes(orderDetail.contractStatus),
            }:undefined,
            signStatus: orderDetail?.contractStatus,
            contractNo: orderDetail?.contractNo,
            generateTime: orderDetail.contractCreateDate,
          },
          amounts: {
            totalAmount: orderDetail?.afterVatAmt,
            paidAmount: 0,
            unpaidAmount: orderDetail?.afterVatAmt,
            taxAmount: orderDetail?.vatAmt,
            paidTaxAmount: 0,
            unpaidTaxAmount:  orderDetail?.vatAmt ,
            grossAmount: orderDetail?.sellerIncomeAmt,
            paidGrossAmount: 0,
            unpaidGrossAmount: orderDetail?.sellerIncomeAmt,
            deductAmount: 0, // 根据实际情况填充
          },
          createTime: orderDetail?.createdDate,
        }
      : {}),
    payments: [],
    logistics: [],
    supplyContracts: supplyContracts?supplyContracts.slice(0,1):undefined,
  };
  console.log("Transformed order data",localData)
  return localData;
}
