import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Form, Input, Button, Collapse } from "antd";
import { useParams, useNavigate } from 'react-router-dom'
import { editCompany, companyInfo } from '../../../../promise'

const { Panel } = Collapse;
const panelLayout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 10,
    },
};
interface TAXPAYCOMPANY{
    isDetail: boolean
}
const TaxPayCompany = (props: TAXPAYCOMPANY) => {
    const { isDetail } = props
    const navigate  = useNavigate()
    const searchParams = useParams()
    const [form] = Form.useForm();
    const [collapseList, setCollapseList] = useState(["1"]);
    const [detail, setDetail] = useState<any>({})
    const RenderTaxPay = () => {
        return (
            <Form {...panelLayout} form={form}>
                <Form.Item
                    name="accountName"
                    label="收款账户名"
                    rules={[{ required: true, message: "请输入收款账户名" }]}
                >
                    <Input placeholder="请输入收款账户名" disabled={isDetail}></Input>
                </Form.Item>
                <Form.Item
                    name="account"
                    label="收款账号"
                    rules={[{ required: true, message: "请输入收款账号" }]}
                >
                    <Input placeholder="请输入收款账号" disabled={isDetail}></Input>
                </Form.Item>
                <Form.Item
                    name="branchName"
                    label="支行名称"
                    rules={[{ required: true, message: "请输入支行名称" }]}
                >
                    <Input placeholder="请输入支行名称" disabled={isDetail}></Input>
                </Form.Item>
                <Form.Item
                    name="branchNum"
                    label="银行联行号"
                    rules={[{ required: true, message: "请输入银行联行号" }]}
                >
                    <Input placeholder="请输入银行联行号" disabled={isDetail}></Input>
                </Form.Item>
            </Form>
        );
    };
    useEffect(() => {
        getCompanyInfo()
    }, [])
    // 详情
    const getCompanyInfo = async () => {
        console.log(searchParams, 'kkkkkkkkkkkkk')
        if (!searchParams.id) return 
        const res = await companyInfo(searchParams?.id)
        
        if (res?.code === 200) {
            setDetail(res.data)
            const params = {
                accountName: res?.data?.accountName,
                account: res?.data?.account,
                branchName: res?.data?.branchName,
                branchNum: res?.data?.branchNum
            }
            form.setFieldsValue(params)
        }
    }
    // 
    const handleSave = async () => {
        const res = await form.validateFields()
        let  params = {
            ...res,
            taxRegionId: searchParams?.id
        }
        if (detail?.id) {
            params = {
                ...params,
                id: detail?.id
            }
        }
        const response:any = await editCompany(params)
        if (response?.code === 200) {
            navigate(-1)
        }

    }
    const getCollapeText = (index: string, type: any = "tax") => {
        var i: any = true;
        i = collapseList.indexOf(index) < 0;
        return i ? "展开" : "收起";
    };
    const handleChange = (e: any) => {
        setCollapseList(e);
    };
    const panelList = [
        {
            key: "1",
            label: "提交资料内容设置",
            extra: getCollapeText("1"),
            text: <RenderTaxPay></RenderTaxPay>,
        },
    ];
    return (
        <div className="collapse_container">
            <Collapse
                onChange={handleChange}
                expandIconPosition={"end"}
                defaultActiveKey={collapseList}
            >
                {panelList.map((item: any) => {
                    return (
                        <Panel
                            key={item.key}
                            header={item.label}
                            extra={item.extra}
                        >
                            {item.text}
                        </Panel>
                    );
                })}
            </Collapse>
            {
                !isDetail && <div className={styles.flex_right}>
                    <Button style={{marginRight:'8px'}} onClick={() => {navigate(-1)}}>取消</Button>
                    <Button type="primary" onClick={handleSave}>保存</Button>
                </div>
            }
        </div>
    );
};

export default TaxPayCompany;
