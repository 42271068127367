import { PushChannelOptions } from '@src/constant/supplyChainFinance'
import { Form, Input, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { memo, useEffect, useState } from 'react'
export const CoreEPSignerFormFields: React.FC = memo(() => {
  const [pushType, setPushType] = useState('')
  return <>
    <Form.Item rules={[{ required: true, message: '请选择' }]} label={"消息类型"} name="pushType">
      <Select
        onChange={(value) => setPushType(value)}
      >
        <Select.Option value={`SIGN`}>签署通知</Select.Option>
        <Select.Option value={`REPAYMENT`}>还款通知</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item label={"证件号"} dependencies={['pushType']}
      rules={[
        { required: pushType == 'SIGN' ? true : false, message: '请选择' },
      ]}
      name="certCard"
    >
      <Input></Input>
    </Form.Item >
    <Form.Item rules={[{ required: true, message: '请选择' }]} label={"推送渠道"} name="pushChannel">
      <Select options={PushChannelOptions}></Select>
    </Form.Item>
    <Form.Item rules={[{ required: true, message: '请输入' }]} label={"推送地址"} name="pushAddress">
      <TextArea></TextArea>
    </Form.Item>
    <p style={{ fontSize: 12, paddingLeft: 34, color: 'red' }}>推送渠道为公众号时，以英文“;”作为手机号分割</p>
  </>
})
CoreEPSignerFormFields.displayName = "核心企业添加担保人表单"
export default CoreEPSignerFormFields