import axios from "axios"
import { financeURL } from "@src/utils/invoiceURL";
const baseURL = financeURL();
export const getCoreEnterpriseDetail = (id: string)=>{
  console.log(baseURL,'baseURL');
  
  return axios.get<Common.ResponseData<API.Schema.CoreEnterpriseVo>>(`/coreEnterprise/${id}`,{baseURL: baseURL}).then((res)=>res.data.data)
}

export const getCoreEnterprises = (params: any)=>{
  return axios.get<Common.ResponseData<Common.Paging<API.Schema.CoreEnterpriseVo>>>(`/coreEnterprise`,{params,baseURL}).then((res)=>res.data)
}

export type CreateSignerForCoreEnterpriseResData = {
  coreEnterpriseId: string,
  pushAddress: string,
  pushChannel: "MOBILE"|"CP_WX",
  certCard: string
}
export const postCreateSignerForCoreEnterprise = (params: CreateSignerForCoreEnterpriseResData)=>{
  const {coreEnterpriseId,...rest} = params
  return axios.post(`/coreEnterprise/${coreEnterpriseId}/signerPush`,rest,{baseURL})
}

export const postDeleteSignerForCoreEnterprise = (params: {
  coreEnterpriseId: string,
  signerPushId: string,
})=>{
  const {coreEnterpriseId,signerPushId} = params
  return axios.delete(`/coreEnterprise/${coreEnterpriseId}/signerPush/${signerPushId}`,{baseURL})
}

export const postUpdateSignerForCoreEnterprise = (params: CreateSignerForCoreEnterpriseResData&{signerPushId:string})=>{
  const {coreEnterpriseId,signerPushId,...rest} = params
  return axios.put(`/coreEnterprise/${coreEnterpriseId}/signerPush/${signerPushId}`,rest,{baseURL})
}