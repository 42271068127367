import React, { useEffect, useState } from "react";
import { Badge, Button, Tooltip } from "antd";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import TableCom from "@src/components/TableCom";
import SearchForm from "@src/components/FormItem";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import styles from "../../index.module.scss";
import { financingStatusOptions, repaymentModeObj } from "./config";
import { getOrderList } from "../promise";
import * as XLSX from "xlsx";
import { SearchOutlined } from "@ant-design/icons";
import { formatAmount } from "@src/utils/utils";
import OverflowEllipsisText from "@src/components/OverflowEllipsisText";
import ExportList from "@src/components/exportExcelByBroswer";
import { useTable } from "@src/hooks/useTable";
import { getRangeDateOnUnitOfTime } from "@src/utils/date/utils";
import { omit } from "lodash";
import moment from "moment";
const breads = [
    {
        label: "供应链金融",
        path: "/supply",
    },
    {
        label: "融资订单",
        path: "",
    },
];
const FinancingOrders = () => {
    const navigate = useNavigate();
    const [pageOptions, setPageOptions] = useState({
        pageSize: 10,
        current: 1,
    });
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<any>({});
    const [list, setList] = useState([]);
    const getList = async (object?: any) => {
        console.log(object, '00000')
        setSearch(object || {});
        setLoading(true);
        try {
            const params = {
                size: pageOptions.pageSize,
                current: pageOptions.current,
                ...object,
            };
            if (object?.current && object?.size) {
                setPageOptions({
                    current: object?.current,
                    pageSize: object?.size,
                });
            }
            const res = await getOrderList(params);
            if (res?.code === 200) {
                console.log(res, "lisyt");
                setList(res?.data?.records);
                setTotal(res?.data?.total);
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };
    useEffect(() => {
        getList();
    }, []);
    const onSearch = (values: any) => {
        console.log(values, "vvvvvvvv");
        const params: any = {
            orderStatus: values.orderStatus,
            keyword: values.keyword,
        };
        if (values?.applyTime && values?.applyTime[0]) {
            params.applyTimeStart =
                dayjs(values.applyTime[0]).format("YYYY-MM-DD") + " 00:00:00";
            params.applyTimeEnd =
                dayjs(values.applyTime[1]).format("YYYY-MM-DD") + " 23:59:59";
        }
        if (values?.loanTime && values?.loanTime[0]) {
            params.loanTimeStart =
                dayjs(values.loanTime[0]).format("YYYY-MM-DD") + " 00:00:00";
            params.loanTimeEnd =
                dayjs(values.loanTime[1]).format("YYYY-MM-DD") + " 23:59:59";
        }
        if (values?.repaymentDate && values?.repaymentDate[0]) {
            params.repaymentDateStart = dayjs(values.repaymentDate[0]).format(
                "YYYY-MM-DD"
            );
            // " 00:00:00";
            params.repaymentDateEnd = dayjs(values.repaymentDate[1]).format(
                "YYYY-MM-DD"
            );
            // " 23:59:59";
        }
        // setSearch(params);
        getList({
            current: 1,
            size: 20,
            ...params,
        });
    };
    const handlePages = (params: any) => {
        getList({
            ...search,
            current: params.current,
            size: params.pageSize,
        });
    };
    const handleExport = () => {
        const workbook = XLSX.utils.book_new();
        const lists: any = [];
        const fields = {};
        columns.map((item: any) => {
            if (item.dataIndex) {
                lists.push({
                    label: item.title,
                    value: item.dataIndex,
                });
                fields[item.dataIndex] = item.title;
            }
        });
        const header = lists.map((item: any) => item.value);
        // const 
        console.log(header, fields, "click");
        const ws = XLSX.utils.json_to_sheet(
            [
                fields,
                ...list.map((item: any) => {
                    let data = {};
                    header.forEach((it: any) => {
                        data[it] = item[it];
                        if (it === "orderStatus") {
                            const obj = financingStatusOptions.find(
                                (cItem: any) =>
                                    cItem.value === item["orderStatus"]
                            );
                            data["orderStatus"] = obj?.label;
                        }
                        if (it === "repaymentMode") {
                            const val = repaymentModeObj[item["repaymentMode"]];
                            data["repaymentMode"] = val;
                        }
                        if (it === "supplierAccess") {
                            const val = item?.supplierAccess?.name;
                            data["supplierAccess"] = val;
                        }
                        if (it === "annualInterestRate") {
                            data["annualInterestRate"] =
                                "年利率" + item["annualInterestRate"] + "%";
                        }
                        // loanCycle
                        if (it === "loanCycle") {
                            data["loanCycle"] = item["loanCycle"] + "天";
                        }
                        if (it === "xzswOrderIds") {
                            data["xzswOrderIds"] = item?.xzswOrderIds.join(',');
                        }
                    });
                    console.log(data, 'datadatadatadata')
                    return data;
                }),
            ],
            {
                header,
                skipHeader: true,
            }
        );
        XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
        XLSX.writeFile(workbook, "导出数据.xlsx", {
            bookType: "xlsx",
        });
    };
    // search
    const formColumns = [
        {
            label: "状态",
            dataIndex: "orderStatus",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: financingStatusOptions,
            },
        },
        {
            label: "申请日期",
            // dataIndex: ["applyTimeStart", "applyTimeEnd"],
            dataIndex: "applyTime",
            type: "DatePickers",
        },
        {
            label: "放款日期",
            dataIndex: "loanTime",
            type: "DatePickers",
        },
        {
            label: "还款日期",
            dataIndex: "repaymentDate",
            type: "DatePickers",
        },
        {
            label: "",
            dataIndex: "keyword",
            type: "Input",
            params: {
                suffix: <SearchOutlined />,
                placeholder:
                    "融资单号/融资产品/融资公司/借款人/核心企业/订单号",
                style: {
                    width: "402px",
                    marginLeft: "20px",
                },
            },
        },
    ];
    const columns = [
        {
            title: "融资单号",
            dataIndex: "financeNo",
            width: 150,
            fixed: "left",
        }, {
            title: "借款人",
            dataIndex: "supplierAccess",
            fixed: "left",
            render: (record: any) => {
                return <span style={{ whiteSpace: 'nowrap' }}>{record?.name}</span>;
            },
        },
        {
            title: "核心企业",
            dataIndex: "coreEnterpriseName",
            width: 200,
        },
        {
            title: "融资产品",
            dataIndex: "productName",
            width: 100,
        },

        {
            title: "融资公司",
            dataIndex: "financeCompany",
            width: 100,
        },
        {
            title: "利率",
            dataIndex: "annualInterestRate",
            width: 150,
            render: (record: any) => "年利率" + record + "%",
        },
        {
            title: "借款周期",
            dataIndex: "loanCycle",
            width: 100,
            render: (record: any) => record + "天",
        },
        {
            title: "还款模式",
            dataIndex: "repaymentMode",
            width: 150,
            render: (record: any) => {
                return <span>{repaymentModeObj[record]}</span>;
            },
        },
        {
            title: "本金",
            dataIndex: "principal",
            width: 150,
            align: 'right',
            render: formatAmount
        },
        {
            title: "利息",
            dataIndex: "interest",
            width: 100,
            align: 'right',
            render: formatAmount
        },
        {
            title: "本息总额",
            dataIndex: "principalInterest",
            width: 150,
            align: 'right',
            render: formatAmount
        },

        {
            title: "罚息",
            dataIndex: "penaltyInterest",
            width: 150,
            align: 'right',
            render: formatAmount
        },
        {
            title: "已还金额",
            dataIndex: "repaidAmount",
            width: 150,
            align: 'right',
            render: formatAmount
        },
        {
            title: "剩余应还",
            dataIndex: "remainingAmount",
            width: 150,
            align: 'right',
            render: formatAmount
        },


        {
            title: "申请时间",
            dataIndex: "applyTime",
            width: 150,
        },
        {
            title: "放款时间",
            dataIndex: "loanTime",
            width: 150,
        },
        {
            title: "还款时间",
            dataIndex: "repaymentDate",
            width: 150,
        },
        {
            title: "关联订单号",
            dataIndex: "xzswOrderIds",
            width: 100,
            render: (record: any) => {
                return <span>{record ? record.toString() : record}</span>;
            },
        },
        {
            title: "备注",
            dataIndex: "remark",
            // width: 50,
            // ellipsis:{showTitle: true},
            render: (text: string) => {
                return text ? <OverflowEllipsisText width={308}>{text}</OverflowEllipsisText> : ""
            }
        },
        {
            title: "状态",
            fixed: "right",
            dataIndex: "orderStatus",
            width: 100,
            render: (record: any) => {
                const obj = financingStatusOptions.find(
                    (item: any) => item.value === record
                );
                // @ts-ignore
                return <Badge status={obj?.status} text={obj?.label} />;
            },
        },
        {
            title: "操作",
            fixed: "right",
            width: 100,
            render: (record: any) => {
                return (
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(
                                `/supply/financingOrders/info/${record?.id}`
                            );
                        }}
                    >
                        详情
                    </Button>
                );
            },
        },
    ];
    const [isExport, setIsExport] = useState(false);
    const exportData = (header: any, fields: any, list: any[]) => {
        console.log(header, fields, list, 'header, fields, list')
        const workbook = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(
            [
                fields,
                ...list.map((item: any) => {
                    let data = {};
                    header.forEach((it: any) => {
                        data[it] = item[it];
                        if (it === "orderStatus") {
                            const obj = financingStatusOptions.find(
                                (cItem: any) =>
                                    cItem.value === item["orderStatus"]
                            );
                            data["orderStatus"] = obj?.label;
                        }
                        if (it === "repaymentMode") {
                            const val = repaymentModeObj[item["repaymentMode"]];
                            data["repaymentMode"] = val;
                        }
                        if (it === "supplierAccess") {
                            const val = item?.supplierAccess?.name;
                            data["supplierAccess"] = val;
                        }
                        if (it === "annualInterestRate") {
                            data["annualInterestRate"] =
                                "年利率" + item["annualInterestRate"] + "%";
                        }
                        // loanCycle
                        if (it === "loanCycle") {
                            data["loanCycle"] = item["loanCycle"] + "天";
                        }
                        if (it === "xzswOrderIds") {
                            data["xzswOrderIds"] = item?.xzswOrderIds.join(',');
                        }
                    });
                    return data;
                }),
            ],
            {
                header,
                skipHeader: true,
            }
        );
        XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
        XLSX.writeFile(workbook, `融资订单${moment().format('YYYY-MM-DD')}.xlsx`, {
            bookType: "xlsx",
        });
        setIsExport(false)
    }
    return (
        <div className={styles.supply_container}>
            {isExport && (
                <ExportList
                    columnsKey={() => {
                        let keys = [...columns];
                        keys.pop()
                        return keys;
                    }}
                    exportName={`融资订单${dayjs().format('YYYY-MM-DD')}.xlsx`}
                    url={"/finance/admin/finance/order"}
                    total={total || 0}
                    size={pageOptions.pageSize || 0}
                    current={pageOptions.current || 0}
                    serchObj={{
                        "sorts[0].field": "createTime",
                        "sorts[0].direction": "DESCENDING",
                        ...omit(search, "current", "size"),
                    }}
                    cancelModal={() => {
                        setIsExport(false);
                    }}
                    parentDownload={exportData}
                />
            )}
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div className={styles.supply_search}>
                <SearchForm
                    formParams={formColumns}
                    onSearch={onSearch}
                ></SearchForm>
            </div>
            <div className={styles.supply_tables}>
                <div className={styles.supply_tables_button}>
                    <Button type="primary" onClick={() => {
                        setIsExport(true)
                    }}>
                        导出数据
                    </Button>
                </div>
                <TableCom
                    rowKey={(record: any) => record?.id}
                    dataSource={list}
                    columns={columns}
                    pagination={{
                        ...pageOptions,
                        total,
                    }}
                    scroll={{ x: 'max-content' }}
                    onChange={handlePages}
                ></TableCom>
            </div>
        </div>
    );
};

export default FinancingOrders;
