import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import styles from "./index.module.scss";
import TableCom from "@src/components/TableCom";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import SearchForm from "@src/components/FormItem";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { productOrders, getTaxs, closePructor } from "../../promises";
import {
    contractStatusOptions,
    orderInvoiceStatusOptions,
    orderTypesOptions,
    contractStatusColors,
} from "./config";
const breads = [
    {
        label: "订单管理",
        path: "",
    },
    {
        label: "采购订单",
        path: "",
    },
];
const ProcureOrder = () => {
    const [loading, setLoading] = useState(false);
    const [pageOptions, setPageOptions] = useState({
        pageSize: 20,
        current: 1,
        total: 0,
    });
    const [list, setList] = useState([{}]);
    const [taxs, setTaxs] = useState<any>([]);
    const [search, setSearch] = useState<any>({});
    const formColumns = [
        {
            label: "创建时间",
            dataIndex: "time",
            type: "DatePickers",
        },
        {
            label: "税区",
            dataIndex: "taxRegionIds",
            type: "Select",
            params: {
                options: taxs.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                })),
                placeholder: "请选择",
            },
        },
        {
            label: "签署状态",
            dataIndex: "contractStatus",
            type: "Select",
            params: {
                options: contractStatusOptions,
                placeholder: "请选择",
            },
        },
        {
            label: "搜索",
            dataIndex: "keyword",
            type: "Input",
            params: {
                placeholder: "输入订单ID、采购方、销售方、合同号、搜索",
                style: {
                    width: "350px",
                },
            },
        },
    ];
    useEffect(() => {
        getLists();
        getAllTaxs();
    }, []);
    // 获取所有的税区
    const getAllTaxs = async () => {
        const res: any = await getTaxs({
            current: 1,
            size: 1000,
        });
        if (res?.code === 200) {
            setTaxs(res?.data?.records);
        }
    };
    const getLists = async (obj?: any) => {
        setLoading(true);
        const params = {
            keywordType: "TRADE",
            size: pageOptions.pageSize,
            current: pageOptions.current,
            ...obj,
        };
        const res = await productOrders(params);
        setLoading(false);
        if (res?.code === 200) {
            setList(res?.data?.records);
            setPageOptions({
                ...pageOptions,
                current: params.current,
                pageSize: params.size,
                total: res?.data?.total,
            });
        }
        console.log(res, "res");
    };
    const onSearch = (values: any) => {
        let params: any = {};
        const keys = Object.keys(values);
        keys.forEach((item: string) => {
            if (values[item] !== null) {
                params[item] = values[item];
            }
        });
        const startTime = values.time
            ? dayjs(values.time[0]).format("YYYY-MM-DD") + " 00:00:00"
            : "";
        const endTime = values.time
            ? dayjs(values.time[1]).format("YYYY-MM-DD") + " 23:59:59"
            : "";
        if (values.time && values.time.length !== 0) {
            params.startTime = startTime;
            params.endTime = endTime;
        }
        params = {
            timeType:'CREATE_TIME',
            ...params,
            size: pageOptions.pageSize,
            current: pageOptions.current,
        };
        delete params?.time
        setSearch(params);
        getLists(params);
    };
    const handlePage = (params: any) => {
        getLists({
            ...search,
            current: params.current,
            size: params.pageSize,
        });
    };
    const handleClose = async (id: string) => {
        const res: any = await closePructor(id);
        if (res?.code === 200) {
            message.success("操作成功");
            getLists();
        }
    };
    const columns = [
        {
            title: "订单ID",
            // dataIndex: "orderNo",
            render: (record: any) => record?.orderNo && <Link to={`/entity/transaction/procureOrder/${record?.id}`}>{record?.orderNo}</Link>,
        },
        {
            title: "合同签署",
            dataIndex: "contractStatusName",
        },
        {
            title: "采购方",
            dataIndex: "buyerName",
        },
        {
            title: "销售方",
            dataIndex: "sellerName",
        },
        {
            title: "数量/吨",
            dataIndex: "quantity",
        },
        {
            title: "不含税金额",
            dataIndex: "unitPrice",
        },
        {
            title: "增值税",
            dataIndex: "vatAmt",
        },
        {
            title: "订单总额",
            dataIndex: "afterVatAmt",
        },
        // {
        //     title: "平台应收金额",
        //     dataIndex: "platFeeAmt",
        // },
        // {
        //     title: "卖家应收金额",
        //     dataIndex: "sellerIncomeAmt",
        // },
        // {
        //     title: "平台实收金额",
        //     dataIndex: "platFeeAmt",
        // },
        // {
        //     title: "卖家实收金额",
        //     dataIndex: "sellerIncomeAmt",
        // },
        {
            title: "合同编号",
            dataIndex: "contractNo",
        },
        {
            title: "创建日期",
            dataIndex: "orderDate",
        },
        {
            title: "操作",
            fixed: "right",
            render: (record: any) => {
                return (
                    <div style={{ width: "150px" }}>
                        <Link
                            to={`/entity/transaction/procureOrder/${record?.id}`}
                        >
                            详情
                        </Link>
                        {record?.orderStatus !== "CLOSED" && (
                            <Button
                                type="link"
                                onClick={() => handleClose(record?.id)}
                            >
                                关闭订单
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];
    return (
        <div className={styles.procure_conttainer}>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div className={styles.search_container}>
                <SearchForm
                    formParams={formColumns}
                    onSearch={onSearch}
                    optionsList={null}
                    isRealTime={false}
                ></SearchForm>
            </div>
            <div className={styles.procure_list}>
                <div className={styles.procure_list_title}>
                    采购订单列表/{pageOptions.total}
                </div>
                <TableCom
                    rowKey={(record: any) => record.id}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        ...pageOptions,
                    }}
                    loading={loading}
                    onChange={handlePage}
                    scroll={{
                        x: true,
                    }}
                ></TableCom>
            </div>
        </div>
    );
};

export default ProcureOrder;
